
// constants
import { ROLE_HIERARCHY } from "./constants";

const sortFeedbackList = (feedbackList) => {
    return feedbackList?.sort((a, b) => {
        return ROLE_HIERARCHY[b.getCurrentValue("model")] - ROLE_HIERARCHY[a.getCurrentValue("model")];
    });
}

export default sortFeedbackList;
