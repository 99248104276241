
import { useState, useEffect } from "react";

// routing
import { useParams, useHistory, useLocation } from 'react-router-dom';

// styles
import "./EditAssignedExam.scss";
import "../../assets/examStyles.scss";
import "../EditExam/EditExam.scss";

// classes
import ClientData from "../../../../services/classes/clientData/ClientData";
import questionHubClass from "../../services/classes/questionHubClass";
import newQuestionClass from "../../components/Question/services/classes/newQuestionClass";
import StickyButtonClass from "../../components/StickyButtons/StickyButtonClass";
import errorClass from "../../../../services/errors/classes/errorClass";
import buttonClass from "../../../../components/Alert/services/classes/ButtonClass/buttonClass";

// services
import networking from "../../../../services/handleNetworking/networking";
import examsApi from "../../../../services/Networking/exams/exams";
import initQuestionHubs from "../../services/initQuestionHubs";
import getEditedHubs from "../../services/getEditedHubs";
import convertError from "../../../../services/errors/convertError";
import postExam from "../EditExam/services/postExam";
import examChanged from "../EditExam/services/examChanged/examChanged";
import { validationWarningsClass } from "../EditExam/services/errors/validationWarnings/validationWarnings";
import { validationErrorsClass } from "../EditExam/services/errors/validationErrors/validationErrors";
import assignmentApi from "../../../../services/Networking/assignment/assignment";

// components
import ExamInfoAssignedExam from "../../components/ExamInfo/ExamInfoAssignedExam";
import Title from "../../components/Title";
import Loader from "../../../../components/Loader";
import Redux from "../../../../components/Redux/Redux";
import List from "../../../../components/List";
import Question from "../../components/Question";
import StickyButtons from "../../components/StickyButtons";
import LocalModal from "../../../../components/LocalModal";
import Alert from "../../../../components/Alert";

// constants
import { REDUX_ERRORS } from "../../../../components/Redux/services/constants";
import { EDIT } from "../../services/tabConstants";
import { DRAFT, PUBLISHED } from "../../../../services/constants/stateConstants";
import { NO_CONTENT, SUCCESS } from "../../../../services/errors/constants";

const EditAssignedExam = (props) => {

    // routing
    let history = useHistory();
    let location = useLocation();
    let { assignmentID, examID } = useParams();

    // data
    const [exam, updateExam] = useState(null);
    const [assignment, updateAssignment] = useState(null);

    // states
    const [loading, toggleLoading] = useState(true);
    const [getAssignmentLoading, toggleGetAssignmentLoading] = useState(false);
    const [loadingSaving, toggleLoadingSaving] = useState(false);
    const [loadingSavingPublish, toggleLoadingSavingPublish] = useState(false);
    const [lastSelectedState, updateLastSelectedState] = useState(DRAFT);
    const [showExamInfo, toggleShowExamInfo] = useState(true);
    const [showQuestionInfo, toggleShowQuestionInfo] = useState(true);

    const [validate, toggleValidate] = useState(false);
    const [showInValid, toggleShowInvalid] = useState(false);

    const [showUnsavedWarning, toggleShowUnsavedWarning] = useState(false);

    // errors
    const [getExamError, updateGetExamError] = useState(null);
    const [getAssignmentError, updateGetAssignmentError] = useState(null);
    const [saveEditedExamError, updateSaveEditedExamError] = useState(null);
    const [examWarning, updateExamWarning] = useState(new errorClass({errorCode: SUCCESS}));
    const [examError, updateExamError] = useState(new errorClass({errorCode: SUCCESS}));

    const formatExam = (fetchedExam) => {
        const formattedQuestions = initQuestionHubs({ sentQuestions: fetchedExam?.questions });
        updateExam(
            new ClientData({
                id: fetchedExam.examUUID,
                childLists: {
                    feedback: [],
                    questions: formattedQuestions,
                },
                sent: fetchedExam,
            })
        )
    }
    const getExam = async({examState}) => {

        const returned = await networking({
            updateContent: formatExam,
            toggleLoading: exam ? (newValue) => toggleLoadingSavingState(newValue, examState) : toggleLoading,
            
            accessableToAllUsers: false,
            errorOnSuccess: false,

            api: examsApi.getExamByExamId,
            apiParams: { examId: examID, state: DRAFT },
            apiParamsOnFailure: { examId: examID, state: PUBLISHED },

            updateError: updateGetExamError,
        });
    }

    const getAssignment = async() => {
        await networking({
            updateContent: updateAssignment,
            toggleLoading: toggleGetAssignmentLoading,
            errorOnSuccess: false,

            api: assignmentApi.getAssignment,
            apiParams: assignmentID,

            updateError: updateGetAssignmentError,
        });
    }

    useEffect(() => {
        getExam({PUBLISHED});
        getAssignment();
    }, []);

    const toggleLoadingSavingState = (newValue, state) => {
        if (state === DRAFT) {
            toggleLoadingSaving(newValue);
        } else {
            toggleLoadingSavingPublish(newValue);
        }
    }

    const savePressed = () => {
        saving({examState: DRAFT});
    }
    const publishChanges = () => {
        saving({examState: PUBLISHED});
    }

    // examState refers to if saving draft or publishing exam
    const saving = async({examState = null} = {}) => {
    
        toggleLoadingSavingState(true, examState);

        // update for warning
        updateLastSelectedState(examState);

        // getting all edited hubs
        const editedHubsObj = getEditedHubs(
            examState,
            exam.getData(),
            examID,
            exam?.childLists?.questions
        );

        // update error and warning
        convertError({
            errorCode: editedHubsObj.warning,
            updateLocally: updateExamWarning,
            customErrors: (errorCode) => validationWarningsClass.getError(errorCode)
        });
        convertError({
            errorCode: editedHubsObj.error,
            updateLocally: updateExamError,
            customErrors: (errorCode) => validationErrorsClass.getError(errorCode)
        });

        // internal error can also come out of here & this means something happened that should not be possible (like assay question with multiple answers or so).
        // Still implement handeling internal error, so we can see if this occours.
        if ((editedHubsObj.warning === NO_CONTENT || editedHubsObj.error === NO_CONTENT) && showInValid === false) {

            // validate
            toggleValidate(true);

            // pupup and don't send
            toggleShowInvalid(true);

            toggleLoadingSavingState(false, examState);

            return null;
        } else {
            // close popup
            toggleShowInvalid(false);
        }

        // // exam info
        let examIsUpdated = false;

        // post
        const returned = await postExam({

            // info to send
            postExam: editedHubsObj.postExam,
            oldState: exam.getCurrentValue("state"),

            // errors
            updateError: updateSaveEditedExamError,

            // info from parent function
            examIsUpdatedCurrentState: examIsUpdated,
        });

        examIsUpdated = returned.examIsUpdated;

        if (examIsUpdated) {
            await getExam({examState});
        }

        toggleLoadingSavingState(false, examState);

    }

    const close = (forceClose) => {
        // check if unsaved changes
        if (!forceClose && examChanged({
            exam: exam,

            examState: exam.getCurrentValue("state"),
            examId: examID,
            questionHubs: exam?.childLists?.questions
        })) {
            toggleShowUnsavedWarning(true);
            return;
        }
        history.goBack();
    }

    const renderQuestion = (i, questionHub) => {

        return (
            <Question
                key={questionHub.questionId}

                examView={EDIT}

                questionHub={questionHub}

                showQuestionInfo={showQuestionInfo}
                toggleShowQuestionInfo={toggleShowQuestionInfo}
            />
        )
    }

    const addQuestion = () => {
        const newQuestionHub = new questionHubClass();
        const newEditedQuestion = new newQuestionClass();

        newQuestionHub.questionPost = newEditedQuestion;

        const examChategory = exam?.getCurrentValue("category");
        if (examChategory) {
            newQuestionHub.updateProperty("category", examChategory);
        }

        newQuestionHub.number = exam?.childLists?.questions?.length;

        updateExam((exam) => {

            exam.childLists.questions = [
                ...exam.childLists.questions,
                newQuestionHub
            ]

            return exam.clone();
        });
    }

    return (
        <div
            className="examViewUniversal"
        >{
            loading && !exam ? <Loader /> :
            !exam ? "Geen tentamen geselecteerd" :
            <>
                <StickyButtons
                
                    // maybe make the buttons depend on if there are changes!
                    buttons={[
                        loadingSaving ? new StickyButtonClass({

                            title: <Loader />,
                            onClickFunc: null,
                            link: null,
                    
                        }) : new StickyButtonClass({

                            title: "Opslaan",
                            onClickFunc: savePressed,
                            link: null,
                    
                        }),
                        loadingSavingPublish ? new StickyButtonClass({

                            title: <Loader />,
                            onClickFunc: null,
                            link: null,
                    
                        }) : new StickyButtonClass({

                            title: "Publiceren",
                            onClickFunc: publishChanges,
                            link: null,
                    
                        }),
                        new StickyButtonClass({

                            title: "Annuleren",
                            onClickFunc: close,
                            link: null,
                        })
                    ]}
                />

                <Title
                    title={exam?.getCurrentValue("title")}
                    clientData={exam}
                    examView={EDIT}

                    showExamInfo={showExamInfo}
                    toggleShowExamInfo={toggleShowExamInfo}

                    loading={loading || getAssignmentLoading}
                />

                {
                    showExamInfo ?
                    <>
                        <ExamInfoAssignedExam
                            exam={exam}
                            assignment={assignment}
                            assignmentUuid={assignmentID}

                            view={EDIT}
                            isEditor={true}
                        />
                    </> : null
                }

                {
                    !exam || !exam?.childLists?.questions ? null :
                    <List
                        items={exam?.childLists?.questions}
                        renderItem={renderQuestion}
                    />
                }

                <div
                    onClick={() => addQuestion()}
                    className="addQuestion"
                >
                    Vraag toevoegen
                </div>

                <LocalModal
                    show={showUnsavedWarning}
                    toggleShow={toggleShowUnsavedWarning}
                    component={
                        <Alert
                            toggleShow={toggleShowUnsavedWarning} 
                        
                            title="Onopgeslagen veranderingen"
                            description="Er zijn onopgeslagen veranderingen gededacteerd in de toets. Weet je zeker dat je wilt afsluiten?"
                        
                            buttonClasses={[
                                new buttonClass({
                                    title: "Afsluiten annueleren",
                                    buttonFunc: () => toggleShowUnsavedWarning(),
                                }),
                                new buttonClass({
                                    title: "Afsluiten zonder op te slaan",
                                    buttonFunc: () => close(true),
                                }),
                            ]}
                        
                        />
                    }
                />

                <Redux
                    showSuccess={false}
                    varId={REDUX_ERRORS}

                    reduxVar={getExamError}
                />
                <Redux
                    showSuccess={false}
                    varId={REDUX_ERRORS}
                    
                    reduxVar={getAssignmentError}
                />
            </>
        }</div>
    )
}

export default EditAssignedExam;
