
// routing
import { useHistory } from "react-router-dom";

// style
import "./ExamInfo.scss";

// classes
import infoColumnClass from "./services/classes/infoColumnClass";
import infoRowClass from "./services/classes/infoRowClass";

// services
import translateExamCategory from "../../services/translateExamCategory";
import formatDateForUser from "../../../../services/dateTime/formatDateForUser/formatDateForUser";
import deadlinePassed from "../../services/deadlinePassed/deadlinePassed";

// components
import ExamInfo from "./ExamInfo";

// constants
import { ASSIGNED_EXAM } from "../../services/translateExamCategory/categoryConstants";
import { DRAFT } from "../../../../services/constants/stateConstants";
import { CHECK, MAKE } from "../../services/tabConstants";

const ExamInfoAssignedExam = (props) => {

    // routing
    let history = useHistory();

    const toAssignment = () => {
        history.push(`/opdrachten/${props.assignmentUuid}`);
    }

    return (
        <>{
            !props.exam ? null :
            <ExamInfo
                // exam info
                infoColumns={[
                    new infoColumnClass({
                        title: "Toets Info",
                        infoRowList: [
                            ...(props.view === CHECK ? [new infoRowClass({
                                title: "Huidige cijfer",
                                value: props.exam?.getCurrentValue("grade")
                            })] : []),
                            new infoRowClass({
                                title: "Punten",
                                value: `${props.exam?.getCurrentValue("scoredPoints") || props.exam?.getCurrentValue("scoredPoints") === 0 ? `${props.exam?.getCurrentValue("scoredPoints")} / ` : ""} ${props.exam?.getCurrentValue("points") ? props.exam?.getCurrentValue("points") : props.exam?.getCurrentValue("totalPoint")}`
                            }),
                            ...(props.view === CHECK ? [new infoRowClass({
                                title: "Bekeken",
                                value: props.exam?.getCurrentValue("validated") ? "Ja" : "Nee"
                            })] : []),
                            ...(props.exam?.getCurrentValue("category") !== ASSIGNED_EXAM ? [new infoRowClass({
                                id: "category",
                                title: "Categorie",
                                value: translateExamCategory(props.exam?.getCurrentValue("category"))
                            })] : []),
                            ...(props.isEditor ? [
                            new infoRowClass({
                                title: "Status",
                                value: props.exam?.getCurrentValue("state") === DRAFT ? "Draft" : "Gepubliceerd"
                            })] : [])
                        ]
                    }),
                    ...(props.assignment ? [new infoColumnClass({
                        title: "Opdracht info",
                        infoRowList: [
                            new infoRowClass({
                                title: "Naam",
                                value: props.assignment?.name,
                                onClick: () => toAssignment(),
                            }),
                            new infoRowClass({
                                title: "Deadline",
                                value: formatDateForUser(new Date(props.assignment?.submittingDeadline)) || "Geen deadline aangegeven",
                                customStyling: deadlinePassed(new Date(props.assignment?.submittingDeadline)) && props.view === MAKE ? "ExamInfoAssignedExamDeadlineRed" : ""
                            }),
                        ]
                    })] : []),
                ]}
            />
        }</>
    )
}

export default ExamInfoAssignedExam;
