
import { useState } from 'react';

// classes
import StickyButtonClass from "../../../Exams/components/StickyButtons/StickyButtonClass";

// services
import networking from '../../../../services/handleNetworking/networking';
import assignmentApi from '../../../../services/Networking/assignment/assignment';
import convertDateToString from '../../../../services/dateTime/convertDateToString/convertDateToString';
import validateSaveAssignment from '../../../../services/validate/validateSaveAssignment/validateSaveAssignment';

// components
import StickyButtons from "../../../Exams/components/StickyButtons";
import Loader from "../../../../components/Loader";
import Redux from '../../../../components/Redux/Redux';

// constants
import { REDUX_ERRORS } from '../../../../components/Redux/services/constants';
import { SUCCESS } from '../../../../services/errors/constants';
import errorClass from '../../../../services/errors/classes/errorClass';

const SavingButtons = (props) => {

    // state
    const [loadingSaving, toggleLoadingSaving] = useState(false);

    // errors
    const [sendError, updateSendError] = useState(null);

    const cancleEditing = () => {

        // TODO: check for changes!!!

        props.toggleEditingAssignment(false);
    }

    const saveAssignment = async() => {
        if (!props.assignment?.put && props.gradingDeadlineChanged === false) {
            updateSendError(new errorClass({
                status: SUCCESS,
                description: "Geen veranderingen om op te slaan."
            }));
            props.toggleEditingAssignment(false);
            return;
        }

        const valid = validateSaveAssignment({
            assignment: props.assignment?.put || props.assignment?.sent,
            gradingDeadlineChanged: props.gradingDeadlineChanged,
            updateTitleError: props.updateTitleError, 
            updateDeadlineError: props.updateDeadlineError, 
            updateGradingDeadlineError: props.updateGradingDeadlineError
        });

        if (valid) {
            // Convert gradingDeadline and submittingDeadline to ISO strings if they exist and are Date objects
            if (props.assignment?.put.gradingDeadline instanceof Date) {
                props.assignment.put.gradingDeadline = convertDateToString(props.assignment.put.gradingDeadline);
            }
            if (props.assignment?.put.submittingDeadline instanceof Date) {
                props.assignment.put.submittingDeadline = convertDateToString(props.assignment.put.submittingDeadline);
            }

            // TODO: update when description is changed
            delete props.assignment.put.assignmentItems;
            delete props.assignment.put.isAdmin;
            delete props.assignment.put.description;

            const response = await networking({
                toggleLoading: toggleLoadingSaving,
                errorOnSuccess: true,

                api: assignmentApi.putAssignments,
                apiParams: {
                    assignmentUuid: props.assignmentUuid,
                    data: props.assignment?.put
                },

                updateError: updateSendError,
            });

            if (response && response.status === SUCCESS) {
                props.toggleEditingAssignment(false);
                props.getAssignmentDataAndStats();
            }
        }
    }

    return (
        <>
            {
                props.assignment?.sent?.isAdmin === true ?
                <StickyButtons
                    buttons={
                        props.editingAssignment === false ?
                        [
                            new StickyButtonClass({
                                title: "Aanpassen",
                                onClickFunc: () => props.toggleEditingAssignment((value) => !value)
                            })
                        ] : [
                            new StickyButtonClass({
                                title: loadingSaving ? <Loader /> : "Opslaan",
                                onClickFunc: loadingSaving ? null : () => saveAssignment()
                            }),
                            new StickyButtonClass({
                                title: "Annuleren",
                                onClickFunc: () => cancleEditing()
                            }),
                        ]
                    }
                /> : null
            }
        
            <Redux
                showSuccess={false}
                varId={REDUX_ERRORS}
                
                reduxVar={sendError}
            />
        </>
    )
}

export default SavingButtons;
