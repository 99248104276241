
// react
import React, { useState } from 'react';

// font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

// style
import "./Delete.scss";

const Delete = (props) => {
    
    const [confirmDelete, toggleConfirmDelete] = useState(false);
    const initDelete = (e) => {
        // make sure route is not triggerred
        if (!e) e = window.event;
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();

        toggleConfirmDelete((value) => !value);
    }

    const confirmDeletefunc = (e) => {
        if (!e) e = window.event;
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();

        props.onClick();

        toggleConfirmDelete((value) => !value);
    }
    
    return (
        <>

            <FontAwesomeIcon 
                onClick={(e) => confirmDelete ? confirmDeletefunc(e) : initDelete(e)}
                className="Delete"
                icon={faTimesCircle}
            />

            {
                confirmDelete ?
                
                <FontAwesomeIcon 
                    onClick={(e) => initDelete(e)}
                    className="cancleDelete"
                    icon={faTimesCircle}
                />

                : null
            }

        </>
    )
}

export default Delete;
