
// local elements
import Select from "../elements/Select";

// style
import "./Select.scss";

const SelectInput = (props) => {
    
    return (
        <>{
            !props.dataClass ? null :
            <div className={props.useBaseStyle ? "textInputBase" : "selectInput"} >
                
                <div className="selectInputTitle">
                    {props.title}
                </div>

                {
                    !props.errorClass ? null : props.errorClass.errorCode === 200 ? null :
                    
                    props.errorClass.priority === 2 ? 
                    <div className="selectShowErrorP2" >
                        {
                            props.errorClass.description
                        }
                    </div>
                    :
                    <div className="selectShowError" >
                        {
                            props.errorClass.description
                        }
                    </div>
                }
                
                <Select
                    options={props.options}
                    dataClass={props.dataClass}
                    valueKey={props.valueKey}
                    showError={props.errorClass ? props.errorClass.errorCode !== 200 && props.errorClass.priority === 1 : false}
                    updateDataClass={props.updateDataClass}

                    valueIsString={props.valueIsString}

                    customUpdateFunc={props.customUpdateFunc}
                    funcOnChange={props.funcOnChange}

                    updateError={props.updateError}
                />
            </div>
        }</>
    )
}

export default SelectInput;
