
import React, { useState, useEffect } from 'react';

// style
import "./ExamInfo.scss";

// classes
import errorClass from "../../../../services/errors/classes";
import option from "../../../../components/input/elements/Select/classes/option";

// components
import List from "../../../../components/List";
import SelectInput from "../../../../components/input/Select";

// constants
import { VIEW, MAKE, CHECK, ASSIGNMENT, EDIT} from "../../services/tabConstants";
import { NO_CONTENT, SUCCESS } from "../../../../services/errors/constants";
import { PRACTICE_EXAM, FINAL_EXAM } from "../../services/translateExamCategory/categoryConstants";

const ExamInfo = (props) => {

    const editCat = () => {
        if (!props.editedExamInfo) {
            props.initEditExamInfo();
        }
    };

    const [catError, updateCatError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    useEffect(() => {
        if (props.validate || catError.errorCode !== SUCCESS) {
            if (props.editedExamInfo ? !props.editedExamInfo["category"] : !props.examClass["category"]) {
                updateCatError(new errorClass({
                    errorCode: NO_CONTENT,
                    description: "De toets heeft een categorie nodig."
                }));
            }
        }
    }, [props.validate]);

    const returnRow = (i, rowitem) => {
        if (props.examView === EDIT && rowitem.id === "category") {
            return (
                <div
                    key={`${i}_${rowitem?.title}`}
                    className={`
                        examInfoItemView
                    `}
                >
                    
                    {rowitem.title ? `${rowitem.title}: ` : null }
                    
                    <SelectInput

                        options={[
                            new option({
                                value: 0,
                                title: "."
                            }),
                            new option({
                                value: PRACTICE_EXAM,
                                title: "Oefentoets"
                            }),
                            new option({
                                value: FINAL_EXAM,
                                title: "Eindexamen"
                            }),
                        ]}

                        valueKey={rowitem.id}
                        dataClass={props.editedExamInfo ? props.editedExamInfo : props.examClass}
                        updateDataClass={null}

                        funcOnChange={editCat}

                        useBaseStyle={true}
        
                        errorClass={catError}
                        updateError={updateCatError}

                    />
                </div>
            )
        } else if (rowitem.link) {
            // NOT IMPLEMENTED NOR TESTED!
            return (
                <div
                    key={i}
                    className={`
                        examInfoItemView
                        ${rowitem.id === props.currentOrder ? "" : "examInfoItemClickable"}
                        ${rowitem.id === props.currentOrder ? "examinfoItemSelected" : ""}
                    `}
                    onClick={rowitem.id === props.currentOrder ? null : () => props.updateCurrentOrderFunc({takenExamId: rowitem.id})}
                >
                    {rowitem.title ? `${rowitem.title}: ` : null }
                    {rowitem.value}
                </div>
            )
        }

        return (
            <div 
                key={i}
                className={`
                    examInfoItemView
                    ${rowitem.customStyling ? rowitem.customStyling : ""}
                    ${rowitem.onClick ? "examInfoItemClickable" : ""}
                    ${rowitem.id === props.currentOrder ? "examinfoItemSelected" : ""}
                `}

                onClick={() => rowitem.onClick ? rowitem.onClick(rowitem.id) : null}
            >
                {rowitem.title && rowitem.value ? `${rowitem.title}: ` : rowitem.title || null }
                {rowitem.value}
            </div>
        )
    }

    const returnColumn = (i, columnInfo) => {
        return (
            <div 
                key={`${i}_${columnInfo?.title}`}
                className={`
                    examInfoSection
                    ${i+1 < props.infoColumns.length ? "borderExamInfoSection" : null }
                `}
            >
                <div className="examInfoItemView examInfoItemViewTitle" >{
                    columnInfo.title
                }</div>

                {
                    !columnInfo.infoRowList ? null :
                    columnInfo.infoRowList.length === 0 ? null :
                    <List
                        items={columnInfo.infoRowList}
                        renderItem={returnRow}
                    />
                }

            </div>
        )
    }

    return (
        <div className="examInfoView">{

            !props.infoColumns ? null :
            props.infoColumns === 0 ? null :
            <List
                items={props.infoColumns}
                renderItem={returnColumn}
            />

        }</div>
    )
}

export default ExamInfo;
