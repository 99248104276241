
import React, { useState, useEffect } from 'react';

// libaries
import parse from "html-react-parser";

// styles
import "./QuestionFeedback.scss";

// classes
import errorClass from '../../../../../../../../services/errors/classes/errorClass';

// components
import CkTextInput from "../../../../../../../../components/input/CkText/CkText";

// services
import cleanText from '../../../../../../../../services/cleanText';

// constants
import { SUCCESS } from '../../../../../../../../services/errors/constants';
import Review from '../Review/Review';
import PointsEditable from '../../../TopBar/components/Points/components/PointsEditable/PointsEditable';
import SelectNumber from '../../../../../../../../components/input/SelectNumber/SelectNumber';
import feedbackByUser from '../../services/feedbackByUser';
import FeedbackRestoration from '../FeedbackRestoration/FeedbackRestoration';

const Feedback = (props) => {

    const [feedbackErrors, updateFeedbackErrors] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    const updatePoints = (points) => {
        props.updatePoints(points, props.editable);
    }

    const updateFeedback = (valueKey, newText) => {
        // update property
        props.feedback.updateProperty(valueKey, newText);
    }

    const engageEditFeedback = () => {
        props.engageEditFeedback(props.feedback);
    }

    return (
        <div
            className={`questionFeedbackView ${props.feedback?.getCurrentValue("feedback") || (props.editing && props.editable) ? "" : "questionFeedbackNoPaddingBottom"}`}
        >{
            !props.feedback ? "Nog geen geschreven feedback" :
            <>
                <div
                    className="questionFeedbackTop"
                >
                    <div
                        className="questionFeedbackTopLeft"
                    >
                        <div
                            className="questionFeedbackRole"
                        >{
                            props.feedback.getCurrentValue("model")
                        }</div>
                        {
                            props.editingFirstItem ? "" :
                            <div
                                className="questionFeedbackEditToggle"
                                onClick={() => engageEditFeedback()}
                            >{
                                props.feedback?.getCurrentValue("feedback") ? "Feedback aanpassen" : "Feedback schrijven"
                            }</div>
                        }
                    </div>
                    {/* <div>{
                        props.feedback.getCurrentValue("model") === "GPT-4.0" ? <Review /> : ""
                    }</div> */}

                    <SelectNumber
                        number={ props.editable ? props.scoredPoints : props.feedback.getCurrentValue("points") }
                        heighestNumber={props.questionHub.getCurrentValue("totalPoints")}
                        updateNumber={ updatePoints }
                    />
                </div>
                {
                    props.editable ? 
                    <FeedbackRestoration 
                        backupUserFeedback={props.backupUserFeedback}
                        updateBackupUserFeedback={props.updateBackupUserFeedback}
                        restoreBackUpFeedback={props.restoreBackUpFeedback}
                    /> : ""
                }
                <div className="questionFeedback" >{
                    props.editing && props.editable ?
                    <CkTextInput
                        // title="Feedback"

                        valueKey="feedback"
                        dataClass={props.feedback.getData()}

                        customUpdateFunc={updateFeedback}

                        errorClass={feedbackErrors}
                        updateError={updateFeedbackErrors}

                        useBaseStyle={true}
                    />
                    :
                    props.feedback.getCurrentValue("feedback") ? 
                    <div
                        className="questionFeedbackText"
                    >{
                        parse(
                            cleanText({
                                textToClean: props.feedback.getCurrentValue("feedback") || "",
                            })
                        )
                    }</div> : null
                }</div>
            </>
        }</div>
    )
}

export default Feedback;
