
import React, { useState, useEffect } from 'react';

// redux
import { useSelector } from 'react-redux';

// style
import "./Question.scss";

// classes
import errorClass from "../../../../services/errors/classes";
import ClientData from '../../../../services/classes/clientData/ClientData';

// services local
import answerAccess from './services/answerAccess';

// services
import validateEditQuestion from '../../services/validateEditQuestion';
import updateUserPoints from './services/points/updateUserPoints';
import createEmptyFeedback from '../../services/createEmptyFeedback/createEmptyFeedback';
import feedbackByUser from './components/Feedback/services/feedbackByUser';
import getFeedbackRoleOfUser from './components/Feedback/services/getFeedbackRoleOfUser';
import sortFeedbackList from './components/Feedback/services/sortFeedbackList';
import determineUserCorrectBasedOnSent from './services/determineUserCorrectBasedOnSent';

// components local
import Info from "./components/Info";
import AssayAnswers from "./components/AssayAnswers";
import MultipleChoice from "./components/MultipleChoice";
import Image from "./components/Image";
import Title from "./components/Title";
import AddAnswer from "./components/AddAnswer";
import TopBar from "./components/TopBar";
import Feedbacks from './components/Feedback/Feedbacks';

// constants local
import { USER_CORRECT, USER_IN_CORRECT, USER_CORRECT_NO_RESPONSE, USER_HALF_CORRECT } from "./services/constants/userCorrectConstants";

// constants
import { SUCCESS, NO_CONTENT } from "../../../../services/errors/constants";
import { VIEW, MAKE, CHECK, EDIT} from "../../services/tabConstants";
import { ASSAY_QUESTION, MULTIPLE_CHOICE_QUESTION } from "../../services/constants/typeConstants";
import { MODEL_KEY, POINTS_KEY } from './components/Feedback/services/constants';

const Question = (props) => {
    
    // redux
    const user = useSelector(state => state.userReducer);

    const [questionDeleted, updateQuestionDeleted] = useState(false);

    /* statefull question info */
    // type
    const [questionType, updateQuestionType] = useState(props.questionHub.getCurrentValue("type"));
    const updateType = () => {
        updateQuestionType(() => props.questionHub.getCurrentValue("type"));
    }

    // answerhubs
    const [answerhubs, updateAnswerHubs] = useState(props.questionHub.getAnswers());
    const updateAnswers = () => {
        updateAnswerHubs(() => [...props.questionHub.getAnswers()]);
    }

    // also update answer after anything is sent
    useEffect(() => {
        updateAnswers();
        // determineAnswerCorrect();
        // determineScoredPoints();
    }, [props.questionHub]);

    /* question user info */
    // user correct & points scored by student
    const [userCorrect, toggleUserCorrect] = useState(determineUserCorrectBasedOnSent(props.questionHub));
    const [scoredPoints, updateScoredPoints] = useState(props.questionHub?.sentQuestion?.scoredPoints || 0);
    const alterUserPoints = ({ userCorrect, userPoints, feedbackListAlreadyUpdated = false }) => {
        const points = updateUserPoints({
            user,

            newCorrectValue: userCorrect,
            newPoints: userPoints,
        
            questionHub: props.questionHub,

            // state management
            toggleUserCorrect: toggleUserCorrect,
            updatePoints: updateScoredPoints,
            updateFeedbackList: updateFeedbackList,
        });

        const foundFeedbackByUser = feedbackByUser(feedbackList);
        if (foundFeedbackByUser && !feedbackListAlreadyUpdated) {
            foundFeedbackByUser.updateProperty(POINTS_KEY, points);
            updateFeedbackListState(foundFeedbackByUser, true);
        } else if (!feedbackListAlreadyUpdated) {
            const feedbackWithUpdatedPoints = new ClientData({ sent: { points: points }, createEmptyInstance: createEmptyFeedback });
            updateFeedbackListState(feedbackWithUpdatedPoints);
        }
    }

    const [feedbackList, updateFeedbackList] = useState(props.questionHub.feedback ? props.questionHub.feedback : []);
    useEffect(() => {
        updateFeedbackList(props.questionHub.feedback);
    }, [props.questionHub]);
    
    const [backupUserFeedback, updateBackupUserFeedback] = useState(null);
    // requires the new property that will be of the student
    const updateFeedbackListState = (newUserFeedback, basedOnUserFeedback=false) => {

        // new feedback is always made by user
        if (!basedOnUserFeedback) {
            const model = getFeedbackRoleOfUser();
            newUserFeedback.updateProperty(MODEL_KEY, model);
        }
        
        // get user feedback
        if (basedOnUserFeedback) {
            // set user feedback to top of feedback list
            updateFeedbackList((oldFeedbackList) => sortFeedbackList(oldFeedbackList));
        } else {
            // add new feedback
            props.questionHub.feedback = [newUserFeedback, ...props.questionHub.feedback];
            updateFeedbackList((oldFeedbackList) => props.questionHub.feedback);
        }
    }

    /* question states */
    const [showInfo, toggleShowInfo] = useState(true);

    // edit multiple choice answerhubs
    const [oneCorrectAnswer, updateoneCorrectAnswer] = useState(props.questionHub.getCurrentValue("oneCorrect"));

    const [editMCAnswers, toggleEditMCAnswers] = useState(false);

    // obtainable points
    const [points, updatePoints] = useState(props.questionHub.questionPoints());
    const updatePointsFunc = () => {
        updatePoints(props.questionHub.questionPoints());
    }

    // errors
    const [questionError, updateQuestionError] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    useEffect(() => {
    
        // open question is done on a lower level -> there can be multiple assay answerhubs
        if (props.examView === MAKE && props.handedIn !== 0) {

            if (props.questionHub.getCurrentValue("type") === ASSAY_QUESTION) {
                if (!props.questionHub.studentAnswer) {
                    updateQuestionError(new errorClass({
                        errorCode: NO_CONTENT,
                        description: "Niet Ingevuld!"
                    }));
                }
            } else if (props.questionHub.getCurrentValue("type") === MULTIPLE_CHOICE_QUESTION) {
                if (props.questionHub.answerId.length < 1) {
                    updateQuestionError(new errorClass({
                        errorCode: NO_CONTENT,
                        description: "Niet Ingevuld!"
                    }));
                }
            }
        }

        // add check if adding question 

    }, [props.handedIn]);

    // validation
    const [titleWarning, updateTitleWarning] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [categoryError, updateCategoryError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [typeError, updateTypeError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [pointsWarning, updatePointsWarning] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [answerWarning, updateAnswerWarning] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    useEffect(() => {

        if (props.validate || questionError.errorCode !== SUCCESS) {

            validateEditQuestion({
                
                questionHub: props.questionHub,

                // warnings
                updateQuestionError: updateQuestionError,
                updateTitleWarning: updateTitleWarning,
                updateCategoryError: updateCategoryError,
                updateTypeError: updateTypeError,
                updatePointsWarning: updatePointsWarning,
                updateAnswerWarning: updateAnswerWarning,

                // ensure showing errors
                toggleShowInfo: toggleShowInfo,
                toggleEditMCAnswers: toggleEditMCAnswers,

            });
        }

    }, [props.validate]);

    const alignQuestionError = () => {
        if (questionError.errorCode !== SUCCESS) {

            validateEditQuestion({
                
                questionHub: props.questionHub,

                // warnings
                updateQuestionError: updateQuestionError,

                // ensure showing errors
                toggleShowInfo: toggleShowInfo,
                toggleEditMCAnswers: toggleEditMCAnswers,

            });
        }
    }

    // to remove error if all question errors are gone
    useEffect(() => {

        alignQuestionError();

    }, [titleWarning, categoryError, typeError, pointsWarning, answerWarning]);

    return (
        <>{
            questionDeleted ? null :
        
        <div className={`question ${questionError.errorCode !== SUCCESS ? "questionError" : ""}`} >
            
            <TopBar

                examView={props.examView}
                questionHub={props.questionHub}

                updateQuestionHubs={props.updateQuestionHubs}

                triggerReRender={props.triggerReRender}

                // init del
                questionDeleted={questionDeleted}
                updateQuestionDeleted={updateQuestionDeleted}

                // info pointer
                showInfo={showInfo}
                toggleShowInfo={toggleShowInfo}
                showQuestionInfo={props.showQuestionInfo}
                toggleShowQuestionInfo={props.toggleShowQuestionInfo}

                // points
                questionType={questionType}
                answerhubs={answerhubs}

                // obtainable points
                points={points}
                updatePoints={updatePointsFunc}

                // points for student answer
                updateUserPoints={alterUserPoints}
                scoredPoints={scoredPoints}

                // validation
                pointsWarning={pointsWarning}
                updatePointsWarning={updatePointsWarning}

            />
            
            {
                // make exam page ? null :
                !showInfo ? null :
                <Info
                    examView={props.examView}

                    questionHub={props.questionHub}

                    editedQuestions={props.editedQuestions}
                    updateEditedQuestions={props.updateEditedQuestions}
                    
                    question={props.question}
                    editedQuestion={props.editedQuestion}

                    questionType={questionType}
                    updateQuestionType={updateQuestionType}

                    state={props.questionHub.getCurrentValue("state")}

                    toggleEditMCAnswers={toggleEditMCAnswers}

                    answerhubs={answerhubs}
                    updateAnswers={updateAnswers}

                    oneCorrectAnswer={oneCorrectAnswer}
                    updateoneCorrectAnswer={updateoneCorrectAnswer}

                    // validate
                    validate={props.validate}
                    categoryError={categoryError}
                    updateCategoryError={updateCategoryError}
                    typeError={typeError}
                    updateTypeError={updateTypeError}

                    // the questions editors
                    editors={"EtAlia"}

                    user={props.user}
                />
            }

            <Title

                examView={props.examView}
                
                questionHub={props.questionHub}

                // validate
                validate={props.validate}
                titleWarning={titleWarning}
                updateTitleWarning={updateTitleWarning}

            />

            {
                props.questionHub.getCurrentValue("image") || props.examView === EDIT ?
                <>
                    <div className="questionHeader" >
                        Afbeelding
                    </div>
                    <Image
                        questionHub={props.questionHub}
                        question={props.question}
                        editedQuestions={props.editedQuestions}

                        examView={props.examView}
                        dataClass={props.questionHub.getQuestion()}

                    />
                </>
                :
                null
            }

            {

                // View with edit or use access && checking
                props.examView === VIEW || 
                props.examView === MAKE || 
                props.examView === CHECK || 
                props.examView === EDIT ? 

                <div className="questionAnswers" >
                    {
                        !answerAccess({
                            examView: props.examView,
                            userAccess: props.userAccess
                            }) && props.examView === VIEW && questionType === ASSAY_QUESTION ? null :
                        
                        props.examView === MAKE ? 
                            <div className="questionHeader" >{
                                questionType === ASSAY_QUESTION ?
                                "Antwoord invullen"
                                :
                                props.questionHub.oneCorrect() ? 
                                    "Antwoord selecteren"
                                    :
                                    "Antwoord(en) selecteren, meer dan één selecteren is mogelijk"
                            }</div>
                        :
                        <div className="questionHeader" >{
                            questionType === ASSAY_QUESTION && answerhubs ?
                                answerhubs.length > 1 ?
                                props.examView === CHECK ? "" : "Antwoorden"
                                :
                                props.examView === CHECK ? "" : "Antwoord"
                            :
                            // Multiple choice opties for View access!
                            <>
                                
                                Multiple choice antwoorden
                                {
                                    props.examView === EDIT ?
                                    <div
                                        className="editMCButton"
                                        onClick={() => toggleEditMCAnswers((value) => !value)}
                                    >{
                                        editMCAnswers ?
                                            "Antwoorden aanpassen"
                                            :
                                            "Correcte antwoord aanpassen"                                        
                                    }</div>
                                    :
                                    null
                                }
                            </>
                            
                        }</div>
                    }
                    
                    {
                    !answerhubs ? null :
                    questionType === ASSAY_QUESTION ? 
                        <AssayAnswers

                            answerAccess={answerAccess({
                                examView: props.examView,
                                userAccess: props.userAccess
                            })}
                        
                            questionHub={props.questionHub}
                            answerhubs={answerhubs}
                            
                            examView={props.examView}
                            currentOrder={props.currentOrder}
                            accessLevel={props.accessLevel}    
                            
                            handedIn={props.handedIn}

                            question={props.questionHub.getQuestion()}
                            editedQuestion={props.editedQuestion}

                            questionError={questionError}
                            updateQuestionError={updateQuestionError}

                            answers={answerhubs}

                            // checking
                            updateUserPoints={alterUserPoints}
                            userCorrect={userCorrect}

                            // validate
                            validate={props.validate}
                            answerWarning={answerWarning}
                            updateAnswerWarning={updateAnswerWarning}

                            triggerReRender={props.triggerReRender}

                        />
                    :
                    questionType === MULTIPLE_CHOICE_QUESTION ? 
                        <MultipleChoice

                            examView={props.examView}
                            accessLevel={props.accessLevel}

                            answerAccess={answerAccess({
                                examView: props.examView,
                                userAccess: props.userAccess
                            })}

                            questionHub={props.questionHub}
                            
                            points={points}
                            oneCorrectAnswer={oneCorrectAnswer}

                            updatePoints={updatePointsFunc}
                            updateType={updateType}

                            answerhubs={answerhubs}
                            updateAnswers={updateAnswers}

                            editMCAnswers={editMCAnswers}

                            // errors
                            questionError={questionError}
                            updateQuestionError={updateQuestionError}
                            alignQuestionError={alignQuestionError}

                            // warnings
                            answerWarning={answerWarning}
                            updateAnswerWarning={updateAnswerWarning}

                            // validation
                            validate={props.validate}

                        />
                        : 
                        // select question type!
                        null

                    }
                </div>

                :null

            }

            {
                props.examView === EDIT ? 

                    <AddAnswer
                        questionHub={props.questionHub}

                        updateType={updateType}
                        updateAnswers={updateAnswers}
                        toggleEditMCAnswers={toggleEditMCAnswers}

                    />

                :
                null
            }

            {
                props.examView === CHECK ?
                <Feedbacks 
                    accessLevel={props.accessLevel}
                    questionHub={props.questionHub}

                    feedbackList={feedbackList}
                    updateFeedbackList={updateFeedbackListState}

                    backupUserFeedback={backupUserFeedback}
                    updateBackupUserFeedback={updateBackupUserFeedback}

                    // points
                    updateUserPoints={alterUserPoints}
                    scoredPoints={scoredPoints}

                    currentOrder={props.currentOrder}

                    questionId={props.questionHub.questionId}
                /> : null
            }
        </div>

        }</>
    )
}

export default Question;
