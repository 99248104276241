
import React, { useState, useEffect } from 'react';

// routing
import { useParams, useHistory, useLocation } from 'react-router-dom';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../../../services/redux/actions/globalErrors.action";
import Redux from '../../../../components/Redux/Redux';

// services
import convertError from "../../../../services/errors/convertError";
import networking from '../../../../services/handleNetworking/networking/networking';
import access from '../../../../services/access/accessCheck/access';

// networking
import examsApi from '../../../../services/Networking/exams/exams';

// networking local
import fetchExams from "../../services/networking/exams/fetchExams"; // delete file when updated everywhere

// services local
import createUserAnswers from "../../services/creatingUserAnswers";
import postTakenExam from "../../services/networking/takenExams/postTakenExam";
import sendableUserAnswers from "../../services/sendableUserAnswers";
import examInfoConstructor from "../../components/ExamInfo/services/constructor";
import findEditedQuestion from "../../components/Question/services/findEditedQuestion"
import generateAnswers from "../../components/Question/services/generateAnswers";
import getEditedHubs from "../../services/getEditedHubs";

import createEditedExamInfo from "./services/createEditedExamInfo";
import initExam from '../../services/initExam/initExam';
import savingExamInfo from './services/savingExamInfo/savingExamInfo';
import removeQuestions from './services/removeQuestions';
import postExam from './services/postExam';
import putQuestions from './services/putQuestions';
import putAnswers from './services/putAnswers';
import removeAnswers from './services/removeAnswers/removeAnswers';
import examChanged from './services/examChanged/examChanged';

// errors local
import handInErrors from "../../services/errors/handInErrors";
import handleDelErrors from "../../services/errors/handleDelErrors";

// style
import "./EditExam.scss";

// classes local
import userAnswer from "../../services/classes/userAnswer";
import StickyButtonClass from "../../components/StickyButtons/StickyButtonClass";
import editedQuestionClass from "../../components/Question/services/classes/editedQuestionClass";
import newQuestionClass from "../../components/Question/services/classes/newQuestionClass";
import questionHubClass from "../../services/classes/questionHubClass";

// classes
import buttonClass from "../../../../components/Alert/services/classes/ButtonClass";
import examInfoSendableClass from "../../services/classes/examInfoSendableClass";
import questionSendableClass from "../../services/classes/questionSendableClass";
import errorClass from '../../../../services/errors/classes/errorClass';

// errors local
import validationWarnings from './services/errors/validationWarnings';
import validationErrors from './services/errors/validationErrors';

// components local
import Question from "../../components/Question";
import StickyButtons from "../../components/StickyButtons";
import ExamInfo from "../../components/ExamInfo";
import InvalidExam from '../../components/InvalidExam';

// components
import Title from "../../components/Title";
import Loader from '../../../../components/Loader';
import List from "../../../../components/List";
import LocalModal from "../../../../components/LocalModal";
import Alert from "../../../../components/Alert";
import Feedback from "../../../../components/Feedback";
import Breadcrumb from "../../../../components/Breadcrumb";

// constants
import { SUCCESS, NO_RESPONSE, NO_CONTENT } from "../../../../services/errors/constants";
import { VIEW, MAKE, CHECK, ASSIGNMENT, EDIT} from "../../services/tabConstants";
import { VIEW_ACCESS, USE_ACCESS, EDITOR_ACCESS } from "../../../../services/access/contentAccess/contentAccessConstants";
import { DRAFT, PUBLISHED } from '../../../../services/constants/stateConstants';
import { REDUX_ERRORS } from '../../../../components/Redux/services/constants';
import { EDITOR } from '../../../../services/access/constants';

const EditExam = (props) => {

    // routing
    let history = useHistory();

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    // id's
    let { tijdvakID, kaID, examID } = useParams();
    const topicId = parseInt(tijdvakID?.replace("t-", ""));
    const subTopicId = parseInt(kaID?.replace("st-", ""));
    const examId = examID?.replace("e-", "");

    // access
    const [userAccess, updateUserAccess] = useState(EDITOR_ACCESS);

    // exam
    const [examView, updateExamView] = useState(EDIT);
    const [showExamInfo, toggleShowExamInfo] = useState(true);

    const [examClass, updateExamClass] = useState(null);

    // validation
    const [validate, toggleValidate] = useState(false);
    const [showInValid, toggleShowInvalid] = useState(false);

    const [lastSelectedState, updateLastSelectedState] = useState(DRAFT);
    const [examWarning, updateExamWarning] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [examError, updateExamError] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    // errors
    const [fetchExamError, updateFetchExamError] = useState(null);
    const [saveEditedExamError, updateSaveEditedExamError] = useState(null);

    // questionHubs
    const [questionHubs, updateQuestionHubs] = useState([]);

    // questions
    const [showQuestionInfo, toggleShowQuestionInfo] = useState(true);

    // popup before unsaved closing -- Not yet implmented!
    const [showUnsavedWarning, toggleShowUnsavedWarning] = useState(false);

    // laoding
    const [loading, toggleLoading] = useState(true);
    const [loadingSaving, toggleLoadingSaving] = useState(false);
    const [loadingSavingPublish, toggleLoadingSavingPublish] = useState(false);
    useEffect(() => {

        gettingExamInfo({});

    }, []);

    useEffect(() => {
        if (!access({
            accessLevel: EDITOR,
            user: user
        })) {
            // go to view page
            history.replace(`/geschiedenis/${tijdvakID}/${kaID}/${examID}`);
        }
    }, [user]);

    // edits
    // edited exam info
    const [editedExamInfo, updateEditedExamInfo] = useState(null);
    const initEditExamInfo = () => {
        updateEditedExamInfo(new examInfoSendableClass(createEditedExamInfo(examClass)))
    }

    // fetching exam
    const initExamWithFetchedExam = (fetchedExam) => {

        initExam({

            fetchedExam: fetchedExam,
        
            updateExamClass: updateExamClass,
            updateQuestionHubs: updateQuestionHubs,
        
        });

        updateEditedExamInfo(null);

    }
    const gettingExamInfo = async({examState}) => {

        networking({

            updateContent: initExamWithFetchedExam,

            toggleLoading: examClass ? (newValue) => toggleLoadingSavingState(newValue, examState) : toggleLoading,

            accessableToAllUsers: false,
            // logOut:logOut,

            errorOnSuccess: false,
            
            api: examsApi.getExamByExamId,
            apiParams: { examId: examId, state: DRAFT },
            apiParamsOnFailure: { examId: examId, state: PUBLISHED },
        
            updateError: updateFetchExamError,
            // customErrors: topicErrors, // Maybe still add exam not found?

            history: history // maybe not?

        });

        updateSaveEditedExamError(null);

        // if (access({
        //     accessLevel: EDITOR,
        //     user: user
        // })) {
            
        // } else {
        //     // go to view page
        //     history.replace(`/geschiedenis/${topicId}/${subTopicId}/${examId}`);
        // }

    }

    const publishChanges = () => {
        saving({examState: PUBLISHED});
    }

    const savePressed = () => {
        saving({examState: DRAFT});
    }

    const toggleLoadingSavingState = (newValue, state) => {

        if (state === DRAFT) {
            toggleLoadingSaving(newValue);
        } else {
            toggleLoadingSavingPublish(newValue);
        }
    }

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (examChanged({
                examClass,
                editedExamInfo: editedExamInfo,

                examState: examClass.state,
                examId: examId,
                questionHubs: questionHubs
            })) {
                event.preventDefault();
                event.returnValue = "U heeft niet-opgeslagen wijzigingen! Weet u zeker dat u wilt vertrekken?";

                toggleShowUnsavedWarning(true);
            }
        }

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => window.removeEventListener('beforeunload', handleBeforeUnload);

    }, [editedExamInfo, examId, questionHubs]);

    // examState refers to if saving draft or publishing exam
    const saving = async({examState = null} = {}) => {
        
        toggleLoadingSavingState(true, examState);

        // update for warning
        updateLastSelectedState(examState);

        // getting all edited hubs
        const editedHubsObj = getEditedHubs(
            examState, 
            editedExamInfo ? editedExamInfo : examClass,
            examId,
            questionHubs
        );

        // update error and warning
        convertError({
            errorCode: editedHubsObj.warning,
            updateLocally: updateExamWarning,
            customErrors: validationWarnings
        });
        convertError({
            errorCode: editedHubsObj.error,
            updateLocally: updateExamError,
            customErrors: validationErrors
        });

        // internal error can also come out of here & this means something happened that should not be possible (like assay question with multiple answers or so).
        // Still implement handeling internal error, so we can see if this occours.
        if ((editedHubsObj.warning === NO_CONTENT || editedHubsObj.error === NO_CONTENT) && showInValid === false) {

            // validate
            toggleValidate(true);

            // pupup and don't send
            toggleShowInvalid(true);

            toggleLoadingSavingState(false, examState);

            return null;
        } else {
            // close popup
            toggleShowInvalid(false);
        }

        // exam info
        let examIsUpdated = false;

        // add sub-topic id
        editedHubsObj.postExam.subtopicId = subTopicId;

        // post
        const returned = await postExam({

            // info to send
            postExam: editedHubsObj.postExam,
            oldState: examClass.state,

            // errors
            updateError: updateSaveEditedExamError,

            // info from parent function
            examIsUpdatedCurrentState: examIsUpdated,
        });

        examIsUpdated = returned.examIsUpdated;

        if (returned.newExamId && returned.newExamId !== examId) {
            // redirect to new exam
            // history.replace(`/geschiedenis/t-${topicId}/st-${subTopicId}/e-${returned.newExamId}/Examen-Aanpassen`);
        }

        if (examIsUpdated) {
            await gettingExamInfo({examState});
        }

        toggleLoadingSavingState(false, examState);

    }

    const close = (forceClose) => {

        // check if unsaved changes
        if (!forceClose && examChanged({
            examClass,
            editedExamInfo: editedExamInfo,

            examState: examClass.state,
            examId: examId,
            questionHubs: questionHubs
        })) {
            toggleShowUnsavedWarning(true);
            return;
        }
        history.goBack();
    }

    const addQuestion = () => {

        const newQuestionHub = new questionHubClass();
        const newEditedQuestion = new newQuestionClass();

        newQuestionHub.questionPost = newEditedQuestion;

        if (examClass) {
            newQuestionHub.updateProperty("category", examClass.category);
        }

        // does still counts deleted questions!
        // number refers to index number, so start at 0
        newQuestionHub.number = questionHubs.length;

        updateQuestionHubs((questionHubs) => {

            return [...questionHubs, newQuestionHub];
        });

        // DELETE!!!
        // No Id's!!! 💣 💣 💣 😱 😱 😱 💣 💣 💣
        // updateNewlyAddedQuestions((questions) => [...questions, newEditedQuestion]);
        // updateEditedQuestions((editedQuestions) => [...editedQuestions, newEditedQuestion]);

    }

    const renderQuestion = (i, questionHub) => {

        if (!questionHub) {
            return null;
        }

        return (
            <Question
                key={questionHub.questionId}

                user={user}

                userAccess={userAccess}

                examView={examView}
                examClass={examClass}

                questionHub={questionHub}
                updateQuestionHubs={updateQuestionHubs}

                // validate
                validate={validate}

                showQuestionInfo={showQuestionInfo}
                toggleShowQuestionInfo={toggleShowQuestionInfo}
            />
        )
    }

    return (
        <div className="EditExam" >

            <Breadcrumb />

            <StickyButtons
                
                // maybe make the buttons depend on if there are changes!
                buttons={[
                    loadingSaving ? new StickyButtonClass({

                        title: <Loader />,
                        onClickFunc: null,
                        link: null,
                
                    }) : new StickyButtonClass({

                        title: "Opslaan",
                        onClickFunc: savePressed,
                        link: null,
                
                    }),
                    loadingSavingPublish ? new StickyButtonClass({

                        title: <Loader />,
                        onClickFunc: null,
                        link: null,
                
                    }) : new StickyButtonClass({

                        title: "Publiceren",
                        onClickFunc: publishChanges,
                        link: null,
                
                    }),
                    new StickyButtonClass({

                        title: "Annuleren",
                        onClickFunc: close,
                        link: null,
                
                    })
                ]}
            />

            {
                loading & !examClass ? <Loader /> : !examClass ? null :
            
                <Title
                    examView={examView}
                    loading={loading}
                
                    title={examClass.title}
                    examClass={examClass}

                    editedExamInfo={editedExamInfo}
                    updateEditedExamInfo={updateEditedExamInfo}
                    initEditExamInfo={initEditExamInfo}

                    showExamInfo={showExamInfo}
                    toggleShowExamInfo={toggleShowExamInfo}

                    // validate
                    validate={validate}
                />
            
            }

            {
                loading & !examClass ? null : !showExamInfo ? null : !examClass ? null :
                <ExamInfo
                    examView={examView}

                    editedExamInfo={editedExamInfo}
                    updateEditedExamInfo={updateEditedExamInfo}
                    initEditExamInfo={initEditExamInfo}

                    examClass={examClass}
                    infoColumns={examInfoConstructor({
                        examInfo: examClass,
                        editors: ["EtAlia"],

                        isEditor: true
                    })}

                    // validate
                    validate={validate}
                />
            }

            {

                !questionHubs ? null :
                questionHubs.length === 0 ?
                ""
                :
                <List
                    items={questionHubs}
                    renderItem={renderQuestion}
                />

            }

            <div
                onClick={() => addQuestion()}
                className="addQuestion"
            >
                Vraag toevoegen
            </div>
            
            <LocalModal
                show={showInValid}
                toggleShow={toggleShowInvalid}
                runOnToggle={() => toggleValidate(false)}
                component={
                    <InvalidExam

                        toggleShowInvalid={toggleShowInvalid}

                        warningClass={examWarning}
                        errorClass={examError}

                        ignoreWarning={saving}
                        lastSelectedState={lastSelectedState}

                        toggleValidate={toggleValidate}

                    />
                }
            />

            <LocalModal
                show={showUnsavedWarning}
                toggleShow={toggleShowUnsavedWarning}
                component={
                    <Alert
                        toggleShow={toggleShowUnsavedWarning} 
                    
                        title="Onopgeslagen veranderingen"
                        description="Er zijn onopgeslagen veranderingen gededacteerd in de toets. Weet je zeker dat je wilt afsluiten?"
                    
                        buttonClasses={[
                            new buttonClass({
                                title: "Afsluiten annueleren",
                                buttonFunc: () => toggleShowUnsavedWarning(),
                            }),
                            new buttonClass({
                                title: "Afsluiten zonder op te slaan",
                                buttonFunc: () => close(true),
                            }),
                        ]}
                    
                    />
                }
            />

            <Redux
                showSuccess={false}
                varId={REDUX_ERRORS}
                
                reduxVar={fetchExamError}
            />

            <Redux
                showSuccess={true}
                varId={REDUX_ERRORS}
                
                reduxVar={saveEditedExamError}
            />

        </div>
    )
}

export default EditExam;
