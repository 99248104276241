
// react
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../../../services/redux/actions/globalErrors.action";

// routing
import { useParams } from 'react-router-dom';

// networking
import LinksApi from "../../../../services/Networking/Links";

// services
import convertError from "../../../../services/errors/convertError";
import access from "../../../../services/access/accessCheck";

// local components
import LinksTheme from "./components/LinksTheme";
import AddThemeLink from "./components/AddThemeLink";

// components
import Add from "../../../../elements/Add";
import List from "../../../../components/List";
import Loader from '../../../../components/Loader';
import LocalModal from '../../../../components/LocalModal';

// constants
import { EDITOR } from "../../../../services/access/constants";
import { SUCCESS, NO_RESPONSE } from "../../../../services/errors/constants";

// style
import "./Links.scss";

const Links = (props) => {

    // id's
    let { tijdvakID, kaID } = useParams();
    const topicId = parseInt(tijdvakID.replace("t-", ""));
    const subTopicId = parseInt(kaID.replace("st-", ""));

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    const [loading, toggleLoading] = useState(true);
    const [rerender, triggerReRender] = useState(true);

    const [linksList, updateLinksList] = useState([]);
    const [addTheme, toggleAddTheme] = useState(false);

    const [showTitles, ShowTitles] = useState(!user ? false : !user.user ? false : access({
        accessLevel: EDITOR,
        userRoles: user.user.roles
    }) ? true : false);
    useEffect(() => {

        if (user) {
            if (user.user) {

                if (access({
                    accessLevel: EDITOR,
                    userRoles: user.user.roles
                })) {
                    ShowTitles(true);
                }
            }
        } else {
            if (linksList) {
                if (linksList.length <= 1) {
                    ShowTitles(false);
                } else {
                    ShowTitles(true);
                }
            } else {
                ShowTitles(false);
            }
        }

    }, [linksList, user]);

    const fetchLinks = async() => {

        const returned = await LinksApi.getLinkBySubTopicId(subTopicId);

        if (!returned) {

            dispatch(globalErrorsActions.emptyErrorList());

            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: NO_RESPONSE,
                    customErrors: null
                })
            ));

            // something weard happend
            toggleLoading(false);
            return null;
        }

        if (returned.status === SUCCESS) {
            updateLinksList(returned.payload);
        } else {
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: returned.status
                })
            ));
        }

        toggleLoading(false);

    }

    useEffect(() => {

        toggleLoading(true);

        fetchLinks();
    
    }, [rerender]);

    useEffect(() => {

        // this should actually show that it's loading! But somehow its triggerred
        // when any redux variable is changed rather than just the user. So, now
        // it just updates in the background when the user logs in.

        fetchLinks();
    
    }, [user]);

    const renderLinks = (i, LinkData) => {
        return (
            <LinksTheme
                key={i}
                data={LinkData}

                showTitles={showTitles}
                rerender={triggerReRender}
            />
        )
    }

    return (
        <div className="Links" >
            <div className="LinksTitle" >
                Interessante links

                <div className="AddLinksList" >
                    {
                        !user ? null : !user.user ? null : access({
                            accessLevel: EDITOR,
                            userRoles: user.user.roles
                        }) ?
                        <Add
                            onClick={() => toggleAddTheme(true)}
                        />
                        : null
                    }

                    <LocalModal
                        show={addTheme}
                        toggleShow={toggleAddTheme}
                        component={
                            <AddThemeLink
                                toggleShow={toggleAddTheme}
                        
                                triggerReRender={triggerReRender}
                            />
                        }
                    />
                </div>

                {
                    !loading ? null :
                    <div className="loadLinks" >
                        <Loader />
                    </div>
                }
            </div>

            
            {
                linksList.length === 0 ? 
                <div className="noLinks" >
                    Er zijn nog geen links voor dit hoofdstuk
                </div>
                :
                <List
                    items={linksList}
                    renderItem={renderLinks}
                />
            }
        </div>
    )
}

export default Links;
