
// classes
import errorClass from "../../../../services/errors/classes";

// constants
import { SUCCESS } from "../../../../services/errors/constants";

// style
import "./Select.scss";

const Select = (props) => {

    const updateValue = (value) => {
        
        const intValue = props.valueIsString ? NaN : parseInt(value.target.value);
        const isTrue = (value.target.value === 'true');
        const isFalse = (value.target.value === 'false');

        if (props.customUpdateFunc) {
            props.customUpdateFunc(
                props.valueKey, 
                !isNaN(intValue) ? intValue : 
                    isTrue || isFalse ? isTrue : 
                        value.target.value
            );

        } else {
            if (!isNaN(intValue)) {
                props.dataClass[props.valueKey] = intValue;
            } else {
                props.dataClass[props.valueKey] = value.target.value;
            }

            if (props.updateDataClass) {
                props.updateDataClass(props.valueKey, !isNaN(intValue) ? intValue : value.target.value);
            }

            if (props.funcOnChange) {
                props.funcOnChange(!isNaN(intValue) ? intValue : value.target.value);
            }
        }

        if (props.updateError) {
            props.updateError(new errorClass({
                errorCode: SUCCESS
            }));
        }

    }

    const options = () => {

        if (!props.options) {
            return null;
        }

        const renderOptions = props.options.map((option, i) => {

            return (
                <option 
                    key={i}
                    value={option.value}
                    className="selectItem"
                    selected={option.value === props.dataClass[props.valueKey] ? "selected" : null}
                >
                    {option.title}
                </option>
            )
        })

        return renderOptions;

    }

    return (
        <>{
            !props.options ? null :
           <select 
                id={props.valueKey} 
                className={props.showError ? "selectItemError" : "selectItem" } 
                onChange={updateValue} 
            >
                {options()}
            </select>
        }</>
    )
}

export default Select;
