
import React, { useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../../../services/redux/actions/globalErrors.action";
import userActions from "../../../../services/redux/actions/user.action";

// services
import convertError from "../../../../services/errors/convertError";
import { SUCCESS } from "../../../../services/errors/constants";
import logOutErrors from "../../../../services/errors/logOutErrors";

// style
import "./User.scss";

// image
import vector from "../../assets/images/Vector.png";

// elements
import Close from "../../../../elements/Close";

const User = (props) => {

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    const logOut = async() => {
        // clear errors
        dispatch(globalErrorsActions.emptyErrorList());

        // log user in
        dispatch(userActions.logOut());

        // display success
        dispatch(globalErrorsActions.addError(
            convertError({
                errorCode: SUCCESS,
                updateLocally: null,
                customErrors: logOutErrors
            }))
        );
    }

    const [pressed, togglePressed] = useState();
    const whenPressed = () => {
        if (user) {
            if (user.user) {
                togglePressed((value) => !value)
            } else {
                console.log("reg");
            }
        }
    }

    return (
        <>
            {
                
                props.user ?
                
                pressed ? 
                    
                    <div className="UserLogout" onClick={() => whenPressed()} >
                        <div className="logOutButton" onClick={() => logOut()} >
                            UITLOGGEN
                        </div>

                        <div>
                            <Close
                                light={true}
                            />
                        </div>
                    </div>
                
                :

                    <div className="UserButton" onClick={() => whenPressed()} >
                        <img 
                            className="vectorUser"
                            src={vector} 
                            alt="Italian Trulli" 
                        />
                        
                        <div className="nameHolder" >
                            {
                                props.title
                            }
                        </div>
                    </div>

                :

                <div className="UserButton" onClick={() => props.toggleShowReg((value) => !value)} >
                    <img 
                        className="vectorUser"
                        src={vector} 
                        alt="Italian Trulli" 
                    />
                    
                    <div className="nameHolder" >
                        {
                            props.title
                        }
                    </div>
                </div>

            }

        </>
    )
}

export default User;
