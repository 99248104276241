
import { useState, useEffect } from "react";

// routing
import { useParams, useHistory, useLocation } from 'react-router-dom';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../../../services/redux/actions/globalErrors.action";

// styles
import "./TakeAssignedExam.scss";
import "../../assets/examStyles.scss";

// networking
import assignmentUserApi from "../../../../services/Networking/AssignmentUser/AssignmentUser";
import assignmentApi from "../../../../services/Networking/assignment/assignment";
import examEvaluation from "../../../../services/Networking/examEvaluation/examEvaluation";
import examsApi from "../../../../services/Networking/exams/exams";

// classes
import ClientData from "../../../../services/classes/clientData/ClientData";
import CustomErrorsManager from "../../../../services/errors/classes/CustomErrorsManager";
import StickyButtonClass from "../../components/StickyButtons/StickyButtonClass";
import buttonClass from "../../../../components/Alert/services/classes/ButtonClass/buttonClass";

// services
import networking from "../../../../services/handleNetworking/networking";
import sendableUserAnswers from "../../services/sendableUserAnswers";
import allAnswerFilled from "../TakeExam/services/allAnswerFilled";
import handleEnvironment from "../../../../services/handleEnvironment/handleEnvironment";
import deadlinePassed from "../../services/deadlinePassed/deadlinePassed";

// components
import ExamInfoAssignedExam from "../../components/ExamInfo/ExamInfoAssignedExam";
import UnsavedExamChanges from "../../components/UnsavedExamChanges/UnsavedExamChanges";
import Title from "../../components/Title";
import Loader from "../../../../components/Loader";
import Redux from "../../../../components/Redux/Redux";
import List from "../../../../components/List";
import Question from "../../components/Question";
import initQuestionHubs from "../../services/initQuestionHubs";
import StickyButtons from "../../components/StickyButtons";
import LocalModal from "../../../../components/LocalModal";
import Alert from "../../../../components/Alert";

// constants
import { REDUX_ERRORS } from "../../../../components/Redux/services/constants";
import { CHECK, MAKE } from "../../services/tabConstants";
import { SAVE, SUBMIT } from "../TakeExam/constants/constants";
import { SUCCESS, UNAUTHORIZED } from "../../../../services/errors/constants";
import { PUBLISHED } from "../../../../services/constants/stateConstants";
import { ETALIA_ENV } from "../../../../services/handleEnvironment/constants";

const TakeAssignedExam = (props) => {

    // routing
    let history = useHistory();
    let location = useLocation();
    let { assignmentID, examID } = useParams();

    // redux
    const dispatch = useDispatch();

    // data
    const [exam, updateExam] = useState(null);
    const [assignment, updateAssignment] = useState(null);

    // states
    const [showHandInAlert, toggleShowHandInAlert] = useState(0);
    const [getAssignmentLoading, toggleGetAssignmentLoading] = useState(false);
    const [loading, toggleLoading] = useState(true);
    const [loadingSaving, toggleLoadingSaving] = useState(false);
    const [loadingSubmitting, toggleLoadingSubmitting] = useState(false);

    const [showExamInfo, toggleShowExamInfo] = useState(true);
    const [showQuestionInfo, toggleShowQuestionInfo] = useState(true);

    const [showUnsavedWarning, toggleShowUnsavedWarning] = useState(false);
    const [showAutogradingConsent, toggleShowAutogradingConsent] = useState(false);

    // errors
    const [getExamError, updateGetExamError] = useState(null);
    const [postExamError, updatePostExamError] = useState(null);
    const [getAssignmentError, updateGetAssignmentError] = useState(null);

    const formatExam = (fetchedExam) => {

        const formattedQuestions = initQuestionHubs({ sentQuestions: fetchedExam?.questions });

        const formattedExam = new ClientData({
            id: fetchedExam?.examUUID,
            sent: fetchedExam,
        });

        // formatQuestions(formattedExam);

        updateExam(
            new ClientData({
                id: fetchedExam.examUUID,
                childLists: {
                    feedback: [],
                    questions: formattedQuestions,
                },
                sent: fetchedExam,
            })
        );
    }
    const getExam = async() => {
        const returned = await networking({
            updateContent: formatExam,
            toggleLoading: toggleLoading,
            errorOnSuccess: false,

            api: examsApi.getExamByExamId,
            apiParams: { examId: examID, state: PUBLISHED },

            updateError: updateGetExamError,
        });
    }

    const getAssignment = async() => {
        await networking({
            updateContent: updateAssignment,
            toggleLoading: toggleGetAssignmentLoading,
            errorOnSuccess: false,

            api: assignmentApi.getAssignment,
            apiParams: assignmentID,

            updateError: updateGetAssignmentError,
        });
    }

    useEffect(() => {
        getExam();
        getAssignment();
    }, []);

    const renderQuestion = (i, questionHub) => {

        return (
            <Question
                key={questionHub.questionId}

                examView={MAKE}
                handedIn={showHandInAlert}

                questionHub={questionHub}

                showQuestionInfo={showQuestionInfo}
                toggleShowQuestionInfo={toggleShowQuestionInfo}
            />
        )
    }

    const feedbackGeneren = async(order) => {
        if (handleEnvironment() === ETALIA_ENV) {
            return;
        }
        const returned = examEvaluation.postExamEvaluation(examID, order);
    }

    const sendUserAnsers = async(state, generateFeedback) => {

        const toSend = sendableUserAnswers(exam?.childLists.questions, true);

        const returned = await networking({
            toggleLoading: state === SUBMIT ? toggleLoadingSubmitting : toggleLoadingSaving,
            errorOnSuccess: true,

            api: assignmentUserApi.postAssignedExamByUser,
            apiParams: { assignmentUuid: assignmentID, itemUuid: examID, state: state, userAnswers: toSend },

            updateError: updatePostExamError,
            customErrors: (errorCode) => new CustomErrorsManager({
                [SUCCESS]: {
                    title: "Uw aanpassingen zijn opgeslagen"
                },
                [UNAUTHORIZED]: {
                    title: deadlinePassed(new Date(assignment?.submittingDeadline)) ? "Deadline verstreken" : "Geen toegang"
                }
            }).getError(errorCode)
        });

        if (returned && returned.status === SUCCESS && state === SUBMIT) {
            if (generateFeedback && returned?.payload) {
                feedbackGeneren(returned?.payload);
            }
            history.push(`/opdrachten/${assignmentID}`);
        }
    }
    const submitExam = (generateFeedback) => {
        sendUserAnsers(SUBMIT, generateFeedback);
    }
    const initSubmitExam = () => {
        // check if all answers filled
        const skippedQuestion = allAnswerFilled(exam?.childLists.questions);

        if (skippedQuestion) {
            // false +1 is 1 in JS, so it aint pretty but it works with one var
            toggleShowHandInAlert(value => value + 1);
            toggleLoadingSubmitting(false);
        } else {
            toggleShowAutogradingConsent(true);
        }
    }

    const saveExam = () => {
        toggleLoadingSaving(true);
        sendUserAnsers(SAVE, false);
    }

    const close = (forceClose) => {

        // TODO: not functional until student answer is implemented inside student answer

        // // check if unsaved changes
        // if (!forceClose && examChanged({
        //     exam: exam,

        //     examState: exam.getCurrentValue("state"),
        //     examId: examID,
        //     questionHubs: exam?.childLists?.questions
        // })) {
        //     toggleShowUnsavedWarning(true);
        //     return;
        // }

        history.goBack();
    }

    return (
        <div
            className="examViewUniversal"
        >{
            loading && !exam ? <Loader /> :
            !exam ? "Geen tentamen geselecteerd" :
            <>

                <StickyButtons
                    buttons={[
                        loadingSaving ? new StickyButtonClass({

                            title: <Loader />,
                            onClickFunc: null,
                            link: null,
                    
                        }) : new StickyButtonClass({

                            title: "Opslaan",
                            onClickFunc: saveExam,
                            link: null,
                    
                        }),
                        loadingSubmitting ? new StickyButtonClass({

                            title: <Loader />,
                            onClickFunc: null,
                            link: null,
                    
                        }) : new StickyButtonClass({

                            title: "Inleveren",
                            onClickFunc: initSubmitExam,
                            link: null,
                    
                        }),
                        new StickyButtonClass({

                            title: "Sluiten",
                            onClickFunc: close,
                            link: null,
                    
                        })
                    ]}
                />

                <Title
                    title={exam?.getCurrentValue("title")}

                    showExamInfo={showExamInfo}
                    toggleShowExamInfo={toggleShowExamInfo}

                    loading={loading || getAssignmentLoading}
                />

                {
                    showExamInfo ?
                    <>
                        <ExamInfoAssignedExam
                            exam={exam}
                            assignment={assignment}
                            assignmentUuid={assignmentID}

                            view={MAKE}
                        />
                    </> : null
                }

                {
                    !exam || !exam?.childLists?.questions ? null :
                    exam?.childLists?.questions?.length === 0 ?
                    <div className="examNoQuestionsUniversal" >Op dit moment heeft dit tentamen geen vragen.</div> :
                    <List
                        items={exam?.childLists?.questions}
                        renderItem={renderQuestion}
                    />
                }

                <UnsavedExamChanges
                    showUnsavedWarning={showUnsavedWarning}
                    toggleShowUnsavedWarning={toggleShowUnsavedWarning}
    
                    close={close}
                />

                <LocalModal
                    show={showAutogradingConsent}
                    toggleShow={toggleShowAutogradingConsent}
                    component={
                        <Alert
                            toggleShow={toggleShowAutogradingConsent}
                        
                            title="Voorwaarden voor het gebruik van Large Language Models (LLM's) voor automatisch nakijken"
                            description="
                            Limitaties:\n
                            Wij gebruiken een Large Language Model (LLM) om feedback en punten te genereren voor elke vraag op basis van jouw antwoorden op het oefententamen. 
                            Dit systeem, gebaseerd op kunstmatige intelligentie, produceert automatisch feedback zonder menselijke tussenkomst. Het model kan onjuiste feedback of punten opgeven. 
                            Zorg er dus voor dat je de gegenereerde feedback en punten niet blindelings vertrouwt.
                            \n
                            \n
                            Data:\n
                            Jouw antwoorden worden verwerkt op servers van Microsoft, in overeenstemming met de contractuele afspraken van de UvA. Jouw gegevens worden niet opgeslagen door externe partijen en niet gebruikt voor verdere training van het model.
                            \n
                            \n
                            Wachttijd:\n
                            Het genereren van punten en feedback kan tot ongeveer 50 seconden duren.
                            \n
                            \n
                            Contact:\n
                            Heb je vragen? Stuur dan een e-mail naar olaf@etalia.nl of een WhatsApp-bericht naar 0623991946.
                            "
                            buttonClasses={[
                                new buttonClass({
                                    title: "Niet akkoord. Inleveren zonder feedback en punten generere.",
                                    buttonFunc: () => submitExam(false),
                                }),
                                new buttonClass({
                                    title: "Akkoord. Inleveren met feedback en punten genereren.",
                                    buttonFunc: () => submitExam(true),
                                })
                            ]}
                        />
                    }
                />

                <Redux
                    showSuccess={false}
                    varId={REDUX_ERRORS}

                    reduxVar={getExamError}
                />
                <Redux
                    showSuccess={false}
                    varId={REDUX_ERRORS}

                    reduxVar={postExamError}
                />
                <Redux
                    showSuccess={false}
                    varId={REDUX_ERRORS}
                    
                    reduxVar={getAssignmentError}
                />
            </>
        }</div>
    )
}

export default TakeAssignedExam;
