import { useEffect } from "react";

// styles
import "./UnfoldedOverview.scss";

// utils
import selectedSplit from "../../../../../../utils/selectedSplit";

// components
import Filters from "../../../../../../../../../../components/Filters";
import List from "../../../../../../../../../../components/List";
import ExamOverview from "./components/ExamOverview/ExamOverview";
import QuestionOverview from "./components/QuestionOverview/QuestionOverview";

// constants
import { OVERVIEW_FOLDED, VIEW_EXAMS } from "../../../../../../services/constants";

const UnfoldedOverview = (props) => {

    const renderExams = (i, data) => {
        return (
            <ExamOverview
                key={`${data?.assignmentItemUUID}_${data?.userId}`}
                data={data}

                overviewWidth={props.overviewWidth}
                directlyToCheckingClicked={props.directlyToCheckingClicked}

                selectedExamParams={props.selectedExamParams}
            />
        )
    }

    const renderQuestions = (i, data) => {
        return (
            <QuestionOverview
                key={data.id}
                data={data}

                overviewWidth={props.overviewWidth}
                directlyToCheckingClicked={props.directlyToCheckingClicked}

                selectedExamParams={props.selectedExamParams}
            />
        )
    }

    return (
        <div
            className="UnfoldedOverviewView"
        >
                <Filters
                    filtersClass={props.filters}
                    updateFiltersClass={props.updateFilters}

                    fullList={props.fetchedItems}
                    updateFullList={props.updateFetchedItems}

                    // might not need the list, just the update?
                    list={props.items}
                    updateFilteredList={props.updateItems}

                    rerender={props.rerenderFetchedList}

                    hideViewFilters={props.overviewWidth === OVERVIEW_FOLDED ? true : false}

                    styles={{
                        view: "assignedExamOverviewFiltersView"
                    }}
                />

            <div
                className="UnfoldedOverviewExamsView"
            >{
                props.items?.length === 0 && props.loading === false ? <div className="UnfoldedOverviewExamsNoExams" >Er zijn geen tentamens met deze filters</div> :
                <List
                    items={props.items}
                    renderItem={selectedSplit(props.filters) === VIEW_EXAMS ? renderExams : renderQuestions }
                />
            }</div>

        </div>
    )
}

export default UnfoldedOverview;
