
// constants
import { ROLE_HIERARCHY } from "./constants";

const findHighestRoleFeedback = (feedbackList) => {
    if (!feedbackList || !Array.isArray(feedbackList) || feedbackList.length === 0) {
        return undefined;
    }

    let highestFeedback = feedbackList[0];
    // Loop through each feedback object
    feedbackList.forEach(feedbackItem => {
        // If there is no highestFeedback yet, or the current feedback's role is higher than the highestFeedback's role, update highestFeedback
        if (!highestFeedback || ROLE_HIERARCHY[feedbackItem.getCurrentValue('role')] > ROLE_HIERARCHY[highestFeedback.getCurrentValue('model')]) {
            highestFeedback = feedbackItem;
        }
    });
    return highestFeedback;

}

export default findHighestRoleFeedback;
