
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../services/redux/actions/globalErrors.action";

// components
import Loader from "../Loader";

// elements
import Star from "./elements/Star";
import Edit from "./elements/Edit";
import Delete from "./elements/Delete";

// style
import "./AlterContent.scss";

// local services
import deleteErrors from "./services/errors/delete";
import starringErrors from "./services/errors/starring";
import loginAccessErrors from "../../services/access/loginAccessErrors";

// services
import convertError from "../../services/errors/convertError";
import access from "../../services/access/accessCheck";

// constants
import { EDITOR } from "../../services/access/constants";
import { SUCCESS, INTERNAL_ERROR, NO_CONTENT, UNAUTHORIZED } from "../../services/errors/constants";

const AlterContent = (props) => {

    // redux
    const user = useSelector(state => state.userReducer);

    const [starred, toggleStarred] = useState(props.star ? props.star.starred : null);
    useEffect(() => {

        toggleStarred(props.star ? props.star.starred : null);
    
    }, [props.star]);

    const [loadingStarring, toggleLoadingStarring] = useState(false);
    const [loadingDelete, toggleLoadingDelete] = useState(false);

    // redux
    const dispatch = useDispatch();

    // function to check if user is logged in
    const checkLoggedIn = () => {
        if (!user) {
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: UNAUTHORIZED,
                    updateLocally: null,
                    customErrors: loginAccessErrors
                })
            ));
            return null;
        }

        if (!user.user) {
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: UNAUTHORIZED,
                    updateLocally: null,
                    customErrors: loginAccessErrors
                })
            ));
            return null;
        }

        return true;
    }

    const starItem = async(e) => {
        // make action above is not triggerred
        if (!e) e = window.event;
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();

        if (!checkLoggedIn()) {
            return null;
        }

        toggleLoadingStarring(true);

        let response;

        if (starred) {
            response = await props.star.unStarApi(props.star.apiParams);
        } else {
            response = await props.star.starApi(props.star.apiParams);
        }
        
        // nothing returned - something weard happend
        if (!response) {

            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: NO_CONTENT,
                    updateLocally: null,
                    customErrors: null
                })
            ));
        }

        // success returned, update with 200
        if (response.status === SUCCESS) {

            toggleStarred((value) => !value);

            if (props.triggerReRender) {
                props.triggerReRender((value) => !value);
            }

            // props.toggleShow(false);

        } else {

            // show auth errors publicly
            dispatch(globalErrorsActions.addError(
                    convertError({
                    errorCode: response.status,
                    updateLocally: null,
                    customErrors: starringErrors
                }))
            );
        }

        toggleLoadingStarring(false);
    }

    const editItem = async(e) => {
        // make action above is not triggerred
        if (!e) e = window.event;
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();
        if (!checkLoggedIn()) {
            return null;
        }

        props.edit.onClick((value) => !value);
    }

    const deleteItem = async() => {

        if (!checkLoggedIn()) {
            return null;
        }

        toggleLoadingDelete(true);

        const response = await props.delete.api(props.delete.apiParams);
        
        // nothing returned - something weard happend
        if (!response) {

            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: NO_CONTENT,
                    updateLocally: null,
                    customErrors: null
                })
            ));
        }

        // success returned, update with 200
        if (response.status === SUCCESS || response.status === NO_CONTENT) {

            // display success
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: response.status,
                    updateLocally: null,
                    customErrors: deleteErrors
                }))
            );

            toggleLoadingDelete(false);
            if (props.runOnSuccessfulDelete) {
                props.runOnSuccessfulDelete();
            }
            props.triggerReRender((value) => !value);
            
        } else {
            // show auth errors publicly
            dispatch(globalErrorsActions.addError(
                    convertError({
                    errorCode: response.status,
                    updateLocally: null,
                    customErrors: deleteErrors
                }))
            );
        }

        toggleLoadingDelete(false);
    }

    return (
        <div className="AlterContent" >

            {
                !props.star ? null : 
                loadingStarring ? <Loader /> :
                <Star
                    starred={starred}
                    onClick={(e) => starItem(e)}
                />
            }
            {
                !props.edit ? null : 
                <Edit
                    onClick={(e) => editItem(e)}
                />
            }
            {
                !props.delete ? null : 
                !user ? null :
                !user.user ? null : 
                props.isPersonal || access({
                    accessLevel: props.delete.accessLevel ? props.delete.accessLevel : EDITOR,
                    userRoles: user.user.roles
                }) ? 
                loadingDelete ? <Loader /> :
                <Delete
                    onClick={deleteItem}
                /> : null
            }

        </div>
    )
}

export default AlterContent;
