
// react
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../../../services/redux/actions/globalErrors.action";

// routing
import { useParams } from 'react-router-dom';

// networking
import eventsApi from "../../../../services/Networking/events";

// services
import convertError from "../../../../services/errors/convertError";
import access from "../../../../services/access/accessCheck";

// local components
import Category from "./components/Category";
import AddCategory from "./components/addItems/AddCategory"

// components
import Add from "../../../../elements/Add";
import List from "../../../../components/List";
import Loader from '../../../../components/Loader';
import LocalModal from '../../../../components/LocalModal';

// constants
import { EDITOR } from "../../../../services/access/constants";
import { SUCCESS, NO_RESPONSE } from "../../../../services/errors/constants";

// styles
import "./Events.scss";

const Events = (props) => {

    // id's
    let { tijdvakID, kaID } = useParams();
    const topicId = parseInt(tijdvakID.replace("t-", ""));
    const subTopicId = parseInt(kaID.replace("st-", ""));

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    const [loading, toggleLoading] = useState(true);
    const [rerender, triggerReRender] = useState(true);

    const [eventsList, updateEventsList] = useState([]);
    const [addChatagory, toggleAddChatagory] = useState(false);

    const [showTitles, ShowTitles] = useState(!user ? false : !user.user ? false : access({
        accessLevel: EDITOR,
        userRoles: user.user.roles
    }) ? true : false);
    useEffect(() => {

        if (user) {
            if (user.user) {

                if (access({
                    accessLevel: EDITOR,
                    userRoles: user.user.roles
                })) {
                    ShowTitles(true);
                }
            }
        } else {
            if (eventsList) {
                if (eventsList.length <= 1) {
                    ShowTitles(false);
                } else {
                    ShowTitles(true);
                }
            } else {
                ShowTitles(false);
            }
        }

    }, [eventsList]);

    const fetchEvents = async() => {

        const returned = await eventsApi.getEventsBySubTopicId(subTopicId);

        if (!returned) {

            dispatch(globalErrorsActions.emptyErrorList());

            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: NO_RESPONSE,
                    customErrors: null
                })
            ));

            // something weard happend
            toggleLoading(false);
            return null;
        }

        if (returned.status === SUCCESS) {
            updateEventsList(returned.payload);
        } else {
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: returned.status
                })
            ));
        }

        toggleLoading(false);

    }

    useEffect(() => {

        toggleLoading(true);

        fetchEvents();
    
    }, [rerender]);

    useEffect(() => {

        // this should actually show that it's loading! But somehow its triggerred
        // when any redux variable is changed rather than just the user. So, now
        // it just updates in the background when the user logs in.

        fetchEvents();
    
    }, [user]);

    const renderChatagory = (i, chatagoryData) => {
        return (
            <Category
                key={i}
                data={chatagoryData}

                triggerReRender={triggerReRender}

                showTitles={showTitles}
            />
        )
    }

    return (
        <div className="events" >

            <div className="EventsTitleTab" >
                Belangrijkste Gebeurtenissen
                {
                    !user ? null : !user.user ? null : access({
                        accessLevel: EDITOR,
                        userRoles: user.user.roles
                    }) ?
                    <div className="AddChatagory" >
                        <Add
                            onClick={() => toggleAddChatagory(true)}
                        />
                        <LocalModal
                            show={addChatagory}
                            toggleShow={toggleAddChatagory}
                            component={
                                <AddCategory
                                    toggleShow={toggleAddChatagory}
                            
                                    triggerReRender={triggerReRender}
                                />
                            }
                        />
                    </div> : null
                }
            </div>

            {
                loading ? <div className="loadEventsHolder" ><Loader /></div> :

                <List
                    items={eventsList}
                    renderItem={renderChatagory}
                />
            }

        </div>
    )
}

export default Events;
