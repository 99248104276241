
// react
import React from 'react';

// components
import Add from "../../../../../../elements/Add";

// elements
import CheckBox from "../../../../../../components/AlterContent/elements/CheckBox";

// style
import "./FilterOptionFlashcards.scss";

const FilterOption = (props) => {

    // use title of the option to determine if adding is possible
    if (props.data.title === "Definities" || props.data.title === "Feitjes") {
        return (
            <div
                className="filterOptionFlashcard"
            >
                <div
                    className="filterOptionFlashcardsInfo"
                >
                    <CheckBox
                        checked={props.data.selected}
                    />
                    {
                        props.data.title
                    }
                </div>
                
                <Add
                    onClick={(e) => props.addClicked(e, props.data.index)}
                />

            </div>
        )
    }

    return (
        <div
            className="filterOptionFlashcard filterOptionFlashcardsInfo"
        >
            <CheckBox
                checked={props.data.selected}
            />
            {
                props.data.title
            }
        </div>
    )

}

export default FilterOption;
