
const access = ({
    accessLevel,
    userRoles,
    user,
}) => {

    if ((!user || !user.user) && !userRoles) {
        return false;
    }

    if (user) {
        if (user.user && !userRoles) {
            userRoles = user.user.roles;
        }

        // else continue using userRoles
    }

    if (!accessLevel || !userRoles) {
        return false;
    }

    let accessLevels;

    if (Array.isArray(accessLevel)) {
        accessLevels = [...accessLevel];
    } else {
        accessLevels = [accessLevel];
    }

    let accessGranted = false;

    accessLevels.forEach(typeWithAccess => {
        if (userRoles.includes(typeWithAccess)) {
            accessGranted = true;
        }
    });

    return accessGranted;

}

export default access;
