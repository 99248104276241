
import React, { useState } from 'react';

// text
import parse from "html-react-parser";
import cleanText from "../../../../../../services/cleanText";

// redux
import { useSelector } from 'react-redux';

// networking
import eventsApi from "../../../../../../services/Networking/events";

// style
import "./Category.scss";

// services
import access from "../../../../../../services/access/accessCheck";

// local components
import Theme from "../Theme";
import AddCategory from "../addItems/AddCategory";
import AddTheme from "../addItems/AddTheme";

// components
import Add from "../../../../../../elements/Add";
import List from "../../../../../../components/List";
import AlterContent from "../../../../../../components/AlterContent";
import LocalModal from "../../../../../../components/LocalModal";

// constants
import { EDITOR } from "../../../../../../services/access/constants";

const Category = (props) => {

    // redux
    const user = useSelector(state => state.userReducer);

    const [addTheme, toggleAddTheme] = useState(false);

    const [editChategory, toggleEditChategory] = useState(false);
    const toggleEditChategoryFunc = () => {
        toggleEditChategory((value) => !value);
    }

    const renderTheme = (i, themeData) => {
        return (
            <Theme
                key={i}
                data={themeData}

                triggerReRender={props.triggerReRender}
                showTitles={props.showTitles}
                chategoryData={props.data}
            />
        )
    }

    return (
        <div className="ChatagoryHolder" >
            {
                !props.showTitles ? null :
                <div className="chatagoryTitle">
                    {
                        parse(
                            cleanText({
                                textToClean: props.data.title,
                                h1: true,
                                h2: true,
                                h3: true,
                                h4: true,
                                p: true,
                                bold: false,
                                list: true,
                            })
                        )
                    }

                    {
                        !user ? null : !user.user ? null : access({
                            accessLevel: EDITOR,
                            userRoles: user.user.roles
                        }) ?
                        <div className="addTheme" >
                            <Add
                                onClick={() => toggleAddTheme(true)}
                            />
                            <LocalModal
                                show={addTheme}
                                toggleShow={toggleAddTheme}
                                component={
                                    <AddTheme
                                        toggleShow={toggleAddTheme}
                                
                                        triggerReRender={props.triggerReRender}
                                        chategoryData={props.data}
                                    />
                                }
                            />

                            <AlterContent
                                // edit={{
                                //     onClick:toggleEditChategoryFunc
                                // }}
                                delete={{
                                    api: eventsApi.deleteChatByChatId,
                                    apiParams: props.data.importantEventId,
                                }}
                                rerender={props.triggerReRender}
                            />

                            <LocalModal
                                show={editChategory}
                                toggleShow={toggleEditChategory}
                                component={
                                    <AddCategory
                                        toggleShow={toggleEditChategory}
                                        existingData={props.data}
                                
                                        triggerReRender={props.triggerReRender}
                                    />
                                }
                            />
                        </div> : null
                    }
                </div>
            }

            <List
                items={props.data.eventThemes}
                renderItem={renderTheme}
            />

            {renderTheme()}

        </div>
    )
}

export default Category;
