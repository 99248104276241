
import { useState } from "react";

// styles
import "./assignmentSettings.scss";

// services
import buttonClass from "../../../../../../components/Alert/services/classes/ButtonClass/buttonClass";
import networking from "../../../../../../services/handleNetworking/networking";
import assignmentApi from "../../../../../../services/Networking/assignment/assignment";

// components
import InfoItem from "../../../../../../components/InfoItem/InfoItem";
import Boolean from "../../../../../../components/input/Boolean/Boolean";
import LocalModal from "../../../../../../components/LocalModal";
import Alert from "../../../../../../components/Alert";
import Redux from "../../../../../../components/Redux/Redux";

// constants
import { SUCCESS } from "../../../../../../services/errors/constants";
import { REDUX_ERRORS } from "../../../../../../components/Redux/services/constants";

const Settings = (props) => {

    // states
    const [loading, toggleLoading] = useState(false);
    const [showFeedbackSharingWarning, toggleShowFeedbackSharingWarning] = useState(false);

    // errors
    const [getAssignmentError, updateGetAssignmentError] = useState(null);

    const shareWithStudents = async() => {
        const response = await networking({
            toggleLoading: toggleLoading,
            errorOnSuccess: false,

            api: assignmentApi.postShowFeedback,
            apiParams: props.assignment?.id,

            updateError: updateGetAssignmentError,
        });

        if (response && response.status === SUCCESS) {
            props.getAssignment(true);
            toggleShowFeedbackSharingWarning(false);
        }
    }

    return (
        <div
            className="assignmentSettingsView"
        >
            <div
                className="assignmentSettingsTitle"
            >
                Instellingen
            </div>

            {
                props.editingAssignment ? 
                <Boolean
                    title="Identificeerbaar nakijken"

                    valueKey="annonymousGrading"
                    clientData={props.assignment}
                />
                :
                <InfoItem
                    title="Identificeerbaar nakijken"
                    value={props.assignment?.getCurrentValue("annonymousGrading") ? "Ja" : "Nee"}
                />
            }

            {/* {
                props.editingAssignment ? 
                <Boolean
                    title="Lever gestarte opdrachten automatisch in na deadline"

                    valueKey="handinActiveExamsOnDeadline"
                    clientData={props.assignment}
                />
                :
                <InfoItem
                    title="Lever gestarte opdrachten automatisch in na deadline"
                    value={props.assignment?.getCurrentValue("handinActiveExamsOnDeadline") ? "Ja" : "Nee"}
                />
            } */}

            {
                props.editingAssignment ? 
                <Boolean
                    title="AI kan nakijken"

                    valueKey="canAIGrade"
                    clientData={props.assignment}
                />
                :
                <InfoItem
                    title="AI kan nakijken"
                    value={props.assignment?.getCurrentValue("canAIGrade") ? "Ja" : "Nee"}
                />
            }

            {
                props.editingAssignment ? 
                <Boolean
                    title="Studenten kunnen feedback aan zichzelf geven"

                    valueKey="canStudentGrade"
                    clientData={props.assignment}
                />
                :
                <InfoItem
                    title="Studenten kunnen feedback aan zichzelf geven"
                    value={props.assignment?.getCurrentValue("canStudentGrade") ? "Ja" : "Nee"}
                />
            }

            {
                props.editingAssignment ? 
                <Boolean
                    title="Studenten kunnen na de deadline inleveren"

                    valueKey="canSubmittAfterDeadline"
                    clientData={props.assignment}
                />
                :
                <InfoItem
                    title="Studenten kunnen na de deadline inleveren"
                    value={props.assignment?.getCurrentValue("canSubmittAfterDeadline") ? "Ja" : "Nee"}
                />
            }

            {/* {
                props.editingAssignment ? 
                <NumberInput
                    inlineTitle="Pogingen"

                    valueKey="retries"
                    clientData={props.assignment}

                    useBaseStyle={true}
                />
                :
                <InfoItem
                    title="Pogingen"
                    value={props.assignment?.getCurrentValue("retries")}
                />
            } */}

            {/* {
                props.editingAssignment ?
                <SelectItems
                    title="Nakijken door"

                    childListKey="graders"
                    clientData={props.assignment}

                    itemSelectedKey={"selected"}
                    itemTitleKey={"title"}
                />
                :
                <InfoItem
                    title="Nakijken door"
                    value={`
                        ${props.assignment?.childLists.graders?.map((item) => item.getCurrentValue("selected") ? `${item.getCurrentValue("title")}, ` : "").join('').slice(0, -2)}
                    `}
                />
            } */}

            {
                // props.assignment?.getCurrentValue("whenAssigneesSeeCheckAssignments") === VIEW_CHECKED_SUBMITTIONS_ON_ADMIN_SHARED &&
                // props.assignment?.getCurrentValue("userRole") === ASSIGNMENT_ADMIN ?
                props.assignment?.getCurrentValue("isAdmin") === true && props.assignment?.getCurrentValue("canViewFeedback") === false ?
                <div
                    className="assignmentShareWithStudents"
                    onClick={() => toggleShowFeedbackSharingWarning(true)}
                >
                    Deel ingeleverde opdrachten met studenten
                </div> : null
            }

            <Redux
                showSuccess={false}
                varId={REDUX_ERRORS}
                
                reduxVar={getAssignmentError}
            />

            <LocalModal
                show={showFeedbackSharingWarning}
                toggleShow={toggleShowFeedbackSharingWarning}
                component={
                    <Alert
                        toggleShow={toggleShowFeedbackSharingWarning} 
                    
                        title="Feedback Delen"
                        description="Weet je zeker dat je de feedback en ingeleverde opdrachent met de studenten wilt delen? Studenten krijgen dat hun eigen antwoorden, de feedback, punten en de correcte antwoorden/rubriek van de ingeleverde opdrachten te zien."
                    
                        buttonClasses={[
                            new buttonClass({
                                title: "Annueleren",
                                buttonFunc: () => toggleShowFeedbackSharingWarning(false),
                            }),
                            new buttonClass({
                                title: "Feedback delen",
                                buttonFunc: () => shareWithStudents(),
                            }),
                        ]}

                        loading={loading}
                    />
                }
            />
        </div>
    )
}

export default Settings;
