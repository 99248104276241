
// services
import axios from "../services/Axios";
import authHeader from "../services/authHeader";
import handleResponse from "../services/handleResponse";

// temp:
import infoObj from "../../classes/infoObj";
import { SUCCESS } from "../../errors/constants";

const assignedExamApi = {
    postNewExamByAssignmentUuid,
    getTakenExamWithAssignmentUuidAndUserId,
    getHandedInQuestionsWithAssignmentId,
    getTakenExamsWithAssignmentId,
    // getAssignedExamByExamAndAssignmentUUID,
    deleteAssignmentItemFromAssignment,
};

async function postNewExamByAssignmentUuid({ assignmentUuiD, data }) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.post(
            `/assignments/${assignmentUuiD}/createItem?title=${data?.title}`,
            {},
            {
                headers: auth.payload
            }
        )
    );

    return responseObj;
}

async function getTakenExamWithAssignmentUuidAndUserId({ assignmentID, userId }) {
    // const responseObj = await handleResponse(
    //     axios.get(
    //         `/assignedExams?assignmentUuid=${assignmentID}&userId=${userId}`, 
    //         {
    //             headers: auth.payload
    //         }
    //     )
    // );
    // return responseObj;

    return new infoObj({
        status: SUCCESS,
        payload: {
            title: "FirstExam!",
            examId: 88,
            category: 3,
            examUUID: "40deeb60-c84f-11ee-b1d1-000d3ab074e6",
            grade: 3.2,
            scoredPoints: 3,
            totalPoint: 15,
            questions: [
                {
                    questionId: 423,
                    title: "<p>H!</p>",
                    type: 1,
                    image: null,
                    category: 1,
                    oneCorrect: true,
                    scoredPoints: 0,
                    totalPoints: 3,
                    answers: [
                        {
                            answerId: 434,
                            correct: true,
                            points: 3,
                            scorePoints: 0,
                            title: "<p>Correct answer</p>",
                            userResponse: "<p>I am always correct</p>",
                            userChoice: null,
                            userCorrect: null
                        }
                    ],
                    evaluations: [
                        {
                            id: 123,
                            feedback: "Super shit",
                            model: "GPT-4.0",
                            points: 0,
                            type: 3
                        },
                        {
                            id: 2213,
                            feedback: "Best goed",
                            model: "Student",
                            points: 0,
                            type: 2
                        }
                    ]
                }
            ]
        }
    });
}

async function getTakenExamsWithAssignmentId({assignmentUuid, examUuid}) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.get(
            `/assignments/${assignmentUuid}/items/${examUuid}`, 
            {
                headers: auth.payload
            }
        )
    );
    return responseObj;

    return new infoObj({
        status: SUCCESS,
        payload: [
            {
                Splits: 1,
                id: 1,
                StudentName: "Olaf Vrijmoet",
                email: "ovrijmoet96@gmail.com",
                AIFeedback: 2,
                TeacherFeedback: 1,
                ReviewedByeTeacher: 1,
                MissedDeadline: 1,
                points: 0
            },
            {
                Splits: 1,
                id: 2,
                StudentName: "Anna Smith",
                email: "annasmith@example.com",
                AIFeedback: 2,
                TeacherFeedback: 2,
                ReviewedByeTeacher: 1,
                MissedDeadline: 2,
                points: 8
            },
            {
                Splits: 1,
                id: 3,
                StudentName: "Liam Johnson",
                email: "liamjohnson@example.com",
                AIFeedback: 2,
                TeacherFeedback: 1,
                ReviewedByeTeacher: 2,
                MissedDeadline: 1,
                points: 6
            },
            {
                Splits: 1,
                id: 4,
                StudentName: "Sophia Martinez",
                email: "sophiamartinez@example.com",
                AIFeedback: 2,
                TeacherFeedback: 1,
                ReviewedByeTeacher: 2,
                MissedDeadline: 1,
                points: 0
            },
            {
                Splits: 1,
                id: 5,
                StudentName: "Lucas Brown",
                email: "lucasbrown@example.com",
                AIFeedback: 2,
                TeacherFeedback: 2,
                ReviewedByeTeacher: 1,
                MissedDeadline: 2,
                points: 4
            },
            {
                Splits: 1,
                id: 6,
                StudentName: "Emma Wilson",
                email: "emmawilson@example.com",
                AIFeedback: 1,
                TeacherFeedback: 2,
                ReviewedByeTeacher: 1,
                MissedDeadline: 2,
                points: 0
            },
            {
                Splits: 1,
                id: 7,
                StudentName: "Michael Taylor",
                email: "michaeltaylor@example.com",
                AIFeedback: 2,
                TeacherFeedback: 1,
                ReviewedByeTeacher: 2,
                MissedDeadline: 1,
                points: 7
            },
            {
                Splits: 1,
                id: 8,
                StudentName: "Emily Davis",
                email: "emilydavis@example.com",
                AIFeedback: 1,
                TeacherFeedback: 2,
                ReviewedByeTeacher: 1,
                MissedDeadline: 2,
                points: 6
            }
        ]
    });
}

async function getHandedInQuestionsWithAssignmentId( assignmentID ) {
    const auth = await authHeader();

    // const responseObj = await handleResponse(
    //     axios.get(
    //         `/assignedExams/${assignmentID}`, 
    //         {
    //             headers: auth.payload
    //         }
    //     )
    // );
    // return responseObj;

    return new infoObj({
        status: SUCCESS,
        payload: [
            {
                id: 2,
                questionIndex: 1,
                amountOfHandins: 23,
                averagePoints: 3,
                maxPoints: 5,
                amountSkipped: 1,
            },
            {
                id: 3,
                questionIndex: 2,
                amountOfHandins: 23,
                averagePoints: 3,
                maxPoints: 5,
                amountSkipped: 1,
            },
            {
                id: 4,
                questionIndex: 3,
                amountOfHandins: 23,
                averagePoints: 3,
                maxPoints: 5,
                amountSkipped: 1,
            }
        ]
    });

}

async function deleteAssignmentItemFromAssignment(assignmentItemUuid) {
    const auth = await authHeader();
    
    const responseObj = await handleResponse(
        axios.delete(
            `/assignments/item/${assignmentItemUuid}`,
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;
}

export default assignedExamApi;
