
import React, { useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../../../../../services/redux/actions/globalErrors.action";

// networking
import LinksApi from "../../../../../../services/Networking/Links";

// routing
import { useParams } from 'react-router-dom';

// services
import convertError from "../../../../../../services/errors/convertError";
import validateTextInput from "../../../../../../services/validate/textInput";
import defaultTextInputErrors from "../../../../../../services/errors/defaultTextInputErrors";
import access from "../../../../../../services/access/accessCheck";
import cleanText from "../../../../../../services/cleanText";

// style
import "./AddLink.scss";

// components
import TextInput from "../../../../../../components/input/Text";
import SelectInput from "../../../../../../components/input/Select";
import Submit from "../../../../../../components/input/elements/Submit";
import Loader from "../../../../../../components/Loader";

// elements
import Title from "../../../../../../elements/ModalTitle";

// classes
import errorClass from "../../../../../../services/errors/classes";
import option from "../../../../../../components/input/elements/Select/classes/option";
import CkEditorConfig from "../../../../../../components/input/elements/CkText/classes";

// local classes
import linkClass from "../../services/classes/linkClass";
import linkThemeClass from "../../services/classes/linkThemeClass";

// local constants
import { ARTICLE, VIDEO, PODCAST, BOOK, DIFFERENT } from "../../services/constants";

// constants
import { SUCCESS, NO_CONTENT, UNAUTHORIZED } from "../../../../../../services/errors/constants";

const AddLink = (props) => {

    // id's
    let { kaID } = useParams();
    const subTopicId = parseInt(kaID.replace("st-", ""));

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    const [linkData, updateLinkData] = useState(props.existingData ? new linkClass({
        title: props.existingData.title,
        creator: props.existingData.creator,
        type: props.existingData.type,
        url: props.existingData.url
    }) : new linkClass());

    const [titleError, updateTitleError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [creatorError, updateCreatorError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [typeError, updateTypeError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [urlError, updateUrlError] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    const [loading, toggleLoading] = useState(false);

    const validateField = ({
        valueKey,
        validate,
        updateError,
        customErrors
    }) => {
        // validate
        const valid = validate(linkData[valueKey]);

        const errorTemp = convertError({
            errorCode: valid,
            updateLocally: updateError,
            customErrors: customErrors
        });

        if (!errorTemp) {
            return false;
        } else {
            if (errorTemp.errorCode !== SUCCESS) {
                // update global error list
                dispatch(globalErrorsActions.addError(errorTemp));
                return false;
            }
        }
        return true;
    }

    const validateFields = () => {

        let validInput = true;

        // validate title
        if (!validateField({
            valueKey: "title",
            validate: validateTextInput,
            updateError: updateTitleError,
            customErrors: defaultTextInputErrors
        })) validInput = false

        // validate creator
        if (!validateField({
            valueKey: "creator",
            validate: validateTextInput,
            updateError: updateCreatorError,
            customErrors: defaultTextInputErrors
        })) validInput = false

        // validate creator
        if (!validateField({
            valueKey: "type",
            validate: validateTextInput,
            updateError: updateTypeError,
            customErrors: defaultTextInputErrors
        })) validInput = false

        // validate url
        if (!validateField({
            valueKey: "url",
            validate: validateTextInput,
            updateError: updateUrlError,
            customErrors: defaultTextInputErrors
        })) validInput = false

        return validInput;

    }

    const save = async() => {
        toggleLoading(true);

        const validInput = await validateFields();

        if (validInput) {

            let response;

            const sendableClass = new linkThemeClass({
                title: props.themeData.title,
                linkThemeId: props.themeData.linkThemeId,
                links: [linkData]
            });

            if (!props.existingData) {
                response = await LinksApi.addLinkBySubTopicId(subTopicId, sendableClass);
            }

            // nothing returned - something weard happend
            if (!response) {

                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: NO_CONTENT,
                        updateLocally: null,
                        customErrors: null
                    })
                ));
            }

            // success returned, update with 200
            if (response.status === SUCCESS) {

                // display success
                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: response.status,
                        updateLocally: null,
                        customErrors: null
                    }))
                );

                props.triggerReRender((value) => !value);
                props.toggleShow(false);

            } else {
                // show auth errors publicly
                dispatch(globalErrorsActions.addError(
                        convertError({
                        errorCode: response.status,
                        updateLocally: null,
                        customErrors: null
                    }))
                );
            }


        }

        toggleLoading(false);

    }

    return (
        <div className="AddChategory" >
            <Title
                title={props.existingData ? "LINK AANPASSEN" : "LINK TOEVOEGEN"}
            />

            <TextInput
                title="Title"
                
                valueKey="title"
                dataClass={linkData}

                errorClass={titleError}
                updateError={updateTitleError}
            />

            <TextInput
                title="Producent"
                
                valueKey="creator"
                dataClass={linkData}

                errorClass={creatorError}
                updateError={updateCreatorError}
            />

            <SelectInput
                title="LINK TYPE"

                options={[
                    new option({
                        value: ARTICLE,
                        title: "Artiekel"
                    }),
                    new option({
                        value: VIDEO,
                        title: "Video"
                    }),
                    new option({
                        value: PODCAST,
                        title: "Podcast"
                    }),
                    new option({
                        value: BOOK,
                        title: "Boek"
                    }),
                    new option({
                        value: DIFFERENT,
                        title: "Anders"
                    })
                ]}

                valueKey="type"
                dataClass={linkData}
                updateDataClass={null}

                errorClass={typeError}
                updateError={updateTypeError}
            />

            <TextInput
                title="URL"
                
                valueKey="url"
                dataClass={linkData}

                errorClass={urlError}
                updateError={updateUrlError}
            />

            {
                loading ? <Loader />
                :
                <Submit
                    value="OPSLAAN"
                    onClick={save}
                />
            }

        </div>
    )
}

export default AddLink;
