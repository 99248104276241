
import React, { useState, useEffect } from 'react';

// style
import "./DelQConfermation.scss";

// classes
import infoObj from '../../../../../../../../services/classes/infoObj/infoObj';

// networking
import deleteNetworking from '../../../../../../../../services/handleNetworking/deleteNetworking';
import questionApi from '../../../../../../../../services/Networking/question/question';

// components
import Loader from '../../../../../../../../components/Loader/Loader';
import Redux from '../../../../../../../../components/Redux/Redux';

// constants
import { REDUX_ERRORS } from '../../../../../../../../components/Redux/services/constants';
import { SUCCESS } from '../../../../../../../../services/errors/constants';

const DelQConfermation = (props) => {

    // fetch exam errors
    const [delErrors, updateDelErrors] = useState(null);

    // loading del
    const [loading, toggleLoading] = useState(false);

    const deleteQuestion = async() => {

        if (props.questionHub.sentQuestion) {
            // send to backend
            const delStatus = await deleteNetworking({

                // runSatisfied: true,

                toggleLoading: toggleLoading,
                // triggerReRender: null,
                        
                api: questionApi.deleteQuestionByQuestionId,
                apiParams: props.questionHub.questionId,
            
                updateError: updateDelErrors,
                // customErrors: , // Maybe still custom erros?

            });

            // mark question as deleted if confermation from back-end
            if (delStatus.status == SUCCESS) {

                props.updateQuestionDeleted(true);

            }

        } else {

            // remove question from exam
            props.updateQuestionHubs(questionHubs => {
                return questionHubs.filter(questionHub => 
                    !(questionHub.number === props.questionHub.number && questionHub.name === props.questionHub.name)
                );
            });

        }

        // do not reload!!! Because all unsaved changes will be lost!!

    }

    const cancelDelQ = () => {

        props.toggleShowDeleteWarning(false);

    }

    return (

        <div
            className="DelQConfermation"
        >
            
            <div className="DelQConfermationTitle" >
                Permanent Verwijderen?
            </div>

            <div className="DelQConfermationSubTitle" >

                Als u op 'verwijderen' klikt, wordt deze vraag permanent verwijderd. 
                
                Deze actie is niet terug te draaien. 
                
                Door op 'verwijderen' te drukken, wordt de vraag verwijderd uit alle versies van deze toets (ook uit de gepubliceerde versie) en uit alle toetsen waarin deze vraag gebruikt wordt.

                Klik op 'Annuleren' om dit te voorkomen.

            </div>

            
            {
                loading ?
                
                <Loader />

                :
                
                <div
                    className="DelQConfermationUserOptions"
                >

                    <div
                        className="DelQConferm"
                        onClick={() => deleteQuestion()}
                    >

                        Permanent Verwijderen

                    </div>

                    <div
                        className="DelQConfermationCancle"
                        onClick={() => cancelDelQ()}
                    >

                        Annuleren

                    </div>

                </div>
            }

            <Redux
                showSuccess={true}
                varId={REDUX_ERRORS}
                
                reduxVar={delErrors}
            />

        </div>

    )

}

export default DelQConfermation;
