
// local elements
import NumberField from "../elements/Number";

// style
import "./Number.scss"

// contants
import { SUCCESS } from "../../../services/errors/constants";

const NumberInput = (props) => {

    return (
        <>{
            !props.dataClass && !props.clientData ? null :
            
            <div className={props.styles?.view || props.useBaseStyle ? "numberInputBase" : "numberInput"} >
                {
                    !props.title ? null :
                    <div className={props.styles?.title || "numberInputTitle"} >
                        {props.title}
                    </div>
                }
                {
                    !props.errorClass ? null : props.errorClass.errorCode === 200 ? null :
                    <div className={props.styles?.showError || "showError"} >
                        {
                            props.errorClass.description
                        }
                    </div>
                }

                {
                    !props.warningClass ? null : props.warningClass.errorCode == SUCCESS ? null :
                    <div className={props.styles?.showWarning || "showWarning"} >
                        {
                            props.warningClass.description
                        }
                    </div>
                }

                <div
                    className={props.styles?.inputAndInlineTitleView || "numberInputAndTitleView"}
                >
                    {
                        props.inlineTitle ? 
                        <div className="InfoItemTitle" >{ props.inlineTitle }:</div> 
                        : null
                    }

                    <NumberField
                        value={props.dataClass ? props.dataClass[props.valueKey] : props.clientData.getCurrentValue(props.valueKey)}
                        dataObject={props.dataClass}
                        clientData={props.clientData}
                        valueKey={props.valueKey}

                        // error
                        showError={props.errorClass ? props.errorClass.errorCode !== SUCCESS : false}
                        updateError={props.updateError}

                        // warning
                        showWarning={props.warningClass ? props.warningClass.errorCode !== SUCCESS : false}
                        updateWarning={props.updateWarning}

                        resetTyped={props.resetTyped}

                        customUpdateFunc={props.customUpdateFunc}

                        styles={props.styles}
                    />
                </div>
                
            </div>
        }</>
    )
}

export default NumberInput;
