
import React, { useEffect, useState } from 'react';
import './App.scss';
import Layout from './layout/Layout'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

// new
import Subject from "../newSRC/Views/Subject";
import Topic from "../newSRC/Views/Topic";
import SubTopic from "../newSRC/Views/SubTopic";
import AboutUs from "../newSRC/Views/AboutUs";
import ExamPrepPage from '../newSRC/Views/ExamPrepPage/ExamPrepPage';
import ResetPassword from "../newSRC/Views/ResetPassword";
import PrivacyPolicy from '../newSRC/Views/PrivacyPolicy';
import NotFound from "../newSRC/Views/NotFound";
import Footer from '../newSRC/Views/Footer';
import ValidateMail from '../newSRC/Views/ValidateMail';
import Assignment from '../newSRC/Views/Assignment/Assignment';

// exams 2.0
import Exam from '../newSRC/Views/Exams/Exam';
import TakeExam from "../newSRC/Views/Exams/Views/TakeExam";
import EditExam from "../newSRC/Views/Exams/Views/EditExam";
import CheckExam from "../newSRC/Views/Exams/Views/CheckExam";
import GradeAssignedExam from '../newSRC/Views/Exams/Views/GradeAssignedExam/GradeAssignedExam';
import AssignmentAccess from '../newSRC/Views/Assignment/Views/AssignmentAccess/AssignmentAccess';
import Assignments from '../newSRC/Views/Assignments/Assignments';
import EditAssignedExam from '../newSRC/Views/Exams/Views/EditAssignedExam/EditAssignedExam';
import TakeAssignedExam from '../newSRC/Views/Exams/Views/TakeAssignedExam/TakeAssignedExam';
import ReviewFeedbackOnAssignedExam from '../newSRC/Views/Exams/Views/ReviewFeedbackOnAssignedExam/ReviewFeedbackOnAssignedExam';

// importing GA
import ReactGA from 'react-ga';

// componenets
import OidcCallBack from '../newSRC/Views/NavBar/components/Login/OidcCallBack';
import handleEnvironment from '../newSRC/services/handleEnvironment/handleEnvironment';

// constants
import { UVA_ENV } from '../newSRC/services/handleEnvironment/constants';

function AppRouting(props) {
  const [showButton, setShowButton] = useState(false);

    useEffect(() => {
      if (window.REACT_APP_TRACKING === "True") {
        // google analytics stuff
        ReactGA.initialize('UA-197202765-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
      }
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > 300) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      });
  }, []);

  // This function will scroll the window to the top 
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };
    return (
      <BrowserRouter>
        <Layout>
          <Switch>
            <Redirect path="/" exact to={handleEnvironment() === UVA_ENV ? "/opdrachten" : "/geschiedenis"} />
            <Route path="/geschiedenis" exact component={Subject} />
            <Route path="/oidc-login" exact component={OidcCallBack} />
            <Route path="/over-ons" exact component={AboutUs} />
            <Route path="/privacy-policy" exact component={PrivacyPolicy} />
            
            <Route path="/opdrachten" exact component={Assignments} />
            <Route path="/opdrachten/gedeeld/:assignmentID/token/:accessToken" exact component={AssignmentAccess} />
            <Route path="/opdrachten/:assignmentID" exact component={Assignment} />

            {/* <Route path="/opdrachten/:assignmentID/examen/:examID" exact component={AssignedExam} /> */}
            <Route path="/opdrachten/:assignmentID/tentamen/:examID/Aanpassen" exact component={EditAssignedExam} />
            <Route path="/opdrachten/:assignmentID/tentamen/:examID/Nakijken" exact component={GradeAssignedExam} />
            <Route path="/opdrachten/:assignmentID/tentamen/:examID/Inkijken" exact component={ReviewFeedbackOnAssignedExam} />
            <Route path="/opdrachten/:assignmentID/tentamen/:examID/Maken" exact component={TakeAssignedExam} />
      
            {
              handleEnvironment() === UVA_ENV ?
              <Route path="/tentamen-voorbereidingen" exact component={ExamPrepPage} />
              :
              <Route path="/examen-voorbereidingen" exact component={ExamPrepPage} />
            }

            <Route path="/geschiedenis/:tijdvakID" exact component={Topic} />
            <Route path={`/geschiedenis/:tijdvakID/:kaID`} exact component={SubTopic} />

            {/* sub topic exams */}
            <Route path={`/geschiedenis/:tijdvakID/:kaID/:examID`} exact component={Exam} />
            <Route path={`/geschiedenis/:tijdvakID/:kaID/:examID/Examen-Maken`} exact component={TakeExam} />
            <Route path={`/geschiedenis/:tijdvakID/:kaID/:examID/:oderNumb/Examen-Nakijken`} exact component={CheckExam} />
            <Route path={`/geschiedenis/:tijdvakID/:kaID/:examID/Examen-Aanpassen`} exact component={EditExam} />

            {/* exam prep exams */}
            {
              handleEnvironment() === UVA_ENV ?
              <>
                <Route path={`/tentamen-voorbereidingen/:subjectId/:bookId/:tijdvakID/:kaID/:examID`} exact component={Exam} />
                <Route path={`/tentamen-voorbereidingen/:subjectId/:bookId/:tijdvakID/:kaID/:examID/Examen-Maken`} exact component={TakeExam} />
                <Route path={`/tentamen-voorbereidingen/:subjectId/:bookId/:tijdvakID/:kaID/:examID/:oderNumb/Examen-Nakijken`} exact component={CheckExam} />
                <Route path={`/tentamen-voorbereidingen/:subjectId/:bookId/:tijdvakID/:kaID/:examID/Examen-Aanpassen`} exact component={EditExam} />
              </>
              :
              <>
                <Route path={`/examen-voorbereidingen/:subjectId/:bookId/:tijdvakID/:kaID/:examID`} exact component={Exam} />
                <Route path={`/examen-voorbereidingen/:subjectId/:bookId/:tijdvakID/:kaID/:examID/Examen-Maken`} exact component={TakeExam} />
                <Route path={`/examen-voorbereidingen/:subjectId/:bookId/:tijdvakID/:kaID/:examID/:oderNumb/Examen-Nakijken`} exact component={CheckExam} />
                <Route path={`/examen-voorbereidingen/:subjectId/:bookId/:tijdvakID/:kaID/:examID/Examen-Aanpassen`} exact component={EditExam} />
              </>
            }

            <Route path="/wachtwoord-vergeten" exact component={ResetPassword} />
            <Route path="/mail-confomeren" exact component={ValidateMail} />
            
            <Route path="/niet-gevonden" component={NotFound} />
            <Route path="*" component={NotFound} />
            
            <Route path="">
              <Redirect exact to="/geschiedenis" />
            </Route>

          </Switch>
        </Layout>
        <Footer />
        {/* {showButton && (
        <button title='Back to top' onClick={scrollToTop} class="btn btn-light btn-lg back-to-top">&#8679;</button>
      )} */}
      </BrowserRouter>
    );
}

export default AppRouting;
