
import React, { useState, useEffect } from 'react';

// classes
import errorClass from "../../../../services/errors/classes";

// constants
import { SUCCESS } from "../../../../services/errors/constants";

// style
import "./NumberField.scss";
import "../assets/style.scss";

const NumberField = (props) => {

    // we're getting a warning because of this but it works
    const [typed, toggleTyped] = useState(false);
    useEffect(() => {
        
        // makes sure that if the value is changed by outside the component, the value shown is updated
        toggleTyped(false);

    }, [props.resetTyped])

    const [notNumb, toggleNotNumb] = useState(false);

    const onChange = (newValue) => {

        let newText = newValue.target.value;

        newText = newText.replaceAll(" ", "");

        // check if value is a number
        if (newText === "") {
            // show error!
            toggleNotNumb(true);
        } else if (!(/^\d+$/.test(newText))) {
            // show error!
            toggleNotNumb(true);
            return null;
        } else {
            toggleNotNumb(false);
        }

        if (props.customUpdateFunc) {
            
            props.customUpdateFunc(
                props.valueKey,
                newText === "" ? "" : parseInt(newText)
            );

        } else if (props.dataObject) {

            props.dataObject[props.valueKey] = parseInt(newText);

        } else if (props.clientData) {
            props.clientData.updateProperty(props.valueKey, newText === "" ? "" : parseInt(newText));
        }

        toggleTyped(true);

        if (props.updateError) {
            props.updateError(new errorClass({
                errorCode: SUCCESS
            }));
        }

        if (props.updateWarning) {
            props.updateWarning(new errorClass({
                errorCode: SUCCESS
            }));
        }
    }

    return (
        <input 
            type="text" 
            className={`
                ${props.styles?.input || ""}
                ${props.showError || notNumb ? props.styles?.inputError || "numberError" : props.showWarning ? props.styles?.inputWarning || "numberWarning" : null}
                ${notNumb ? props.styles?.notNumberError || "notNumberError" : null}
            `}

            name={props.valueKey}

            value={typed ? undefined : props.value}
            onChange={onChange}
        />
    )

}

export default NumberField;
