
import React, { useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../../../../../services/redux/actions/globalErrors.action";

// networking
import LinksApi from "../../../../../../services/Networking/Links";

// routing
import { useParams } from 'react-router-dom';

// services
import convertError from "../../../../../../services/errors/convertError";
import validateTextInput from "../../../../../../services/validate/textInput";
import defaultTextInputErrors from "../../../../../../services/errors/defaultTextInputErrors";
import access from "../../../../../../services/access/accessCheck";
import cleanText from "../../../../../../services/cleanText";

// style
import "./AddThemeLink.scss";

// components
import CkTextInput from "../../../../../../components/input/CkText";
import Submit from "../../../../../../components/input/elements/Submit";
import Loader from "../../../../../../components/Loader";

// elements
import Title from "../../../../../../elements/ModalTitle";

// classes
import errorClass from "../../../../../../services/errors/classes";
import CkEditorConfig from "../../../../../../components/input/elements/CkText/classes";

// local classes
import linkThemeClass from "../../services/classes/linkThemeClass";

// constants
import { SUCCESS, NO_CONTENT, UNAUTHORIZED } from "../../../../../../services/errors/constants";

const AddThemeLink = (props) => {

    // id's
    let { kaID } = useParams();
    const subTopicId = parseInt(kaID.replace("st-", ""));

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    const [themeData, updateThemeData] = useState(props.existingData ? new linkThemeClass({
        title: props.existingData.title,
        linkThemeId: props.existingData.linkThemeId,
        links: props.existingData.links
    }) : new linkThemeClass());

    const [themeTitleError, updateThemeTitleError] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    const [loading, toggleLoading] = useState(false);

    const validateField = ({
        valueKey,
        validate,
        updateError,
        customErrors
    }) => {
        // validate
        const valid = validate(themeData[valueKey]);

        const errorTemp = convertError({
            errorCode: valid,
            updateLocally: updateError,
            customErrors: customErrors
        });

        if (!errorTemp) {
            return false;
        } else {
            if (errorTemp.errorCode !== SUCCESS) {
                // update global error list
                dispatch(globalErrorsActions.addError(errorTemp));
                return false;
            }
        }
        return true;
    }

    const validateFields = () => {

        let validInput = true;

        // validate chatagoryText
        if (!validateField({
            valueKey: "title",
            validate: validateTextInput,
            updateError: updateThemeTitleError,
            customErrors: defaultTextInputErrors
        })) validInput = false

        return validInput;

    }

    const save = async() => {
        toggleLoading(true);

        const validInput = await validateFields();

        if (validInput) {
            themeData.title = await cleanText({
                textToClean: themeData.title,
                h1: true,
                h2: true,
                h3: true,
                h4: true,
                p: true,
            });

            let response;

            if (!props.existingData) {
                response = await LinksApi.addLinkBySubTopicId(subTopicId, themeData);
            }

            // nothing returned - something weard happend
            if (!response) {

                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: NO_CONTENT,
                        updateLocally: null,
                        customErrors: null
                    })
                ));
            }

            // success returned, update with 200
            if (response.status === SUCCESS) {

                // display success
                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: response.status,
                        updateLocally: null,
                        customErrors: null
                    }))
                );

                props.triggerReRender();
                props.toggleShow(false);

            } else {
                // show auth errors publicly
                dispatch(globalErrorsActions.addError(
                        convertError({
                        errorCode: response.status,
                        updateLocally: null,
                        customErrors: null
                    }))
                );
            }


        }

        toggleLoading(false);

    }

    return (
        <div className="AddChategory" >
            <Title
                title={"CHATEGORIE TOEVEOGEN"}
            />

            <CkTextInput
                config={new CkEditorConfig({
                    toolbar: ['italic', 'link'],
                })}
                
                title="THEMA TITLE"
                
                valueKey="title"
                dataClass={themeData}

                errorClass={themeTitleError}
                updateError={updateThemeTitleError}
            />

            {
                loading ? <Loader />
                :
                <Submit
                    value="OPSLAAN"
                    onClick={save}
                />
            }

        </div>
    )
}

export default AddThemeLink;
