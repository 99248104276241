
import React, { useState } from 'react';

// text
import parse from "html-react-parser";
import cleanText from "../../../../../../services/cleanText";

// redux
import { useSelector } from 'react-redux';

// networking
import eventsApi from "../../../../../../services/Networking/events";

// style
import "./Theme.scss";

// services
import access from "../../../../../../services/access/accessCheck";

// local component
import Event from "../Event";
import AddEvent from "../addItems/AddEvent";

// components
import Add from "../../../../../../elements/Add";
import List from "../../../../../../components/List";
import AlterContent from "../../../../../../components/AlterContent";
import LocalModal from "../../../../../../components/LocalModal";

// constants
import { EDITOR } from "../../../../../../services/access/constants";

const Theme = (props) => {

    // redux
    const user = useSelector(state => state.userReducer);

    const [addEvent, toggleAddEvent] = useState(false);
    
    const renderEvent = (i, eventData) => {
        return (
            <Event
                key={i}
                data={eventData}

                triggerReRender={props.triggerReRender}
            />
        )
    }

    return (
        <div className="ThemeHolder" >
            {
                !props.data ? null :
                <>
                {
                    !props.showTitles ? null :
                    <div className="ThemeTitle" >
                        {
                            parse(
                                cleanText({
                                    textToClean: props.data.title,
                                    h1: true,
                                    h2: true,
                                    h3: true,
                                    h4: true,
                                    p: true,
                                    bold: false,
                                    list: true,
                                })
                            )
                        }
                        {
                            !user ? null : !user.user ? null : access({
                                accessLevel: EDITOR,
                                userRoles: user.user.roles
                            }) ?
                            <div className="addEvent" >
                                <Add
                                    onClick={() => toggleAddEvent(true)}
                                />

                                <AlterContent
                                    // edit={{
                                    //     onClick:toggleEditChategoryFunc
                                    // }}
                                    delete={{
                                        api: eventsApi.deleteThemeByThemeId,
                                        apiParams: props.data.eventThemeId,
                                    }}
                                    rerender={props.triggerReRender}
                                />

                                <LocalModal
                                    show={addEvent}
                                    toggleShow={toggleAddEvent}
                                    component={
                                        <AddEvent
                                            toggleShow={toggleAddEvent}
                                    
                                            triggerReRender={props.triggerReRender}
                                            themeData={props.data}
                                            chategoryData={props.chategoryData}
                                        />
                                    }
                                />
                            </div> : null
                        }

                    </div>
                }

                <List
                    items={props.data.events}
                    renderItem={renderEvent}
                />

                </>
            }
        </div>
    )
}

export default Theme;
