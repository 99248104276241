
class StickyButtonClass {
    
    constructor({

        title = "",
        onClickFunc = null,
        link = null,

    }) {

        this.title = title;
        this.onClickFunc = onClickFunc;
        this.link = link;

    };

}

export default StickyButtonClass;
