
// services
import getEditedHubs from "../../../../services/getEditedHubs/getEditedHubs";

const examChanged = ({
    exam,
    examClass,
    editedExamInfo,

    examState,
    examId,
    questionHubs

} = {}) => {

    if (editedExamInfo || exam.put) {
        return true;
    }

    // getting all edited hubs
    const editedHubsObj = getEditedHubs(
        examState,
        exam?.getData() || examClass,
        examId,
        questionHubs
    );

    return editedHubsObj.changedDetected;

}

export default examChanged;
